import { authenticatedAPI } from 'src/libs/finbits/client';

import type { CreateSicoobConsentmentParams } from './types';

export async function createSicoobConsentment({
  organizationId,
  companyId,
  ...params
}: CreateSicoobConsentmentParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/sicoob_consentment`,
    params
  );

  return response.data;
}
