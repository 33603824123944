import { useEffect, useMemo, useTransition } from 'react';

import { Box, Divider, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import _debounce from 'lodash/debounce';
import { ContentWrapper } from 'src/design-system/components';

import { SearchInput } from 'src/mui';

import InboxItemDetails from 'src/features/inbox-items/InboxItemDetails';
import InboxItemList from 'src/features/inbox-items/InboxItemList';
import InboxItemsCheckbox from 'src/features/inbox-items/InboxItemsCheckbox';
import InboxItemSelect from 'src/features/inbox-items/InboxItemSelect';
import { useInboxItemsContext } from 'src/features/inbox-items/InboxItemsProvider/InboxItemsProvider';
import useInboxItemActions from 'src/features/inbox-items/useInboxItemActions';
import useInboxItemsActions from 'src/features/inbox-items/useInboxItemsActions';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';

import { content, details } from './InboxItems.sx';

export default function InboxItems() {
  const {
    filteredInboxItems,
    isLoading,
    isSearching,
    setIsSearching,
    quickFilter,
    setQuickFilter,
    activeIndex,
    setActiveInboxItemId,
    selectedInboxItemsIds,
  } = useInboxItemsContext();

  const { inboxItemId, status, navigateToInboxItem, closeInboxItem } =
    useInboxItemsParams();
  const { onRejection, isAllowedRejectManyInboxItems } = useInboxItemActions({
    inboxItem: filteredInboxItems.find(
      (inboxItem) => inboxItem.id === inboxItemId
    ),
  });

  const { onRejectionMany } = useInboxItemsActions({
    inboxItemsIds: selectedInboxItemsIds,
  });

  const isCheckboxEnabled =
    isAllowedRejectManyInboxItems && status === 'pending';

  const [, startTransition] = useTransition();

  const quickFilterWithDebounce = useMemo(
    () =>
      _debounce((value: string) => {
        if (value !== '') {
          setQuickFilter(value.trim().split(' '));
        } else {
          setQuickFilter(undefined);
        }

        setIsSearching(false);
      }, 350),
    [setIsSearching, setQuickFilter]
  );

  function handleChangeSearch(value: string) {
    if (inboxItemId) {
      closeInboxItem();
    }

    setIsSearching(true);
    quickFilterWithDebounce(value);
  }

  useEffect(() => {
    if (!inboxItemId) {
      return setActiveInboxItemId(undefined);
    }

    if (activeIndex === -1) {
      const activeInboxItem = filteredInboxItems.find(
        ({ id }) => id === inboxItemId
      );

      setActiveInboxItemId(activeInboxItem?.id);
    }
  }, [activeIndex, inboxItemId, filteredInboxItems, setActiveInboxItemId]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (document.activeElement !== document.body) {
        return;
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();

        const previousIndex = activeIndex - 1;
        const previousInboxItem = filteredInboxItems.at(previousIndex);

        if (!previousInboxItem || activeIndex === 0) return;

        setActiveInboxItemId(previousInboxItem?.id);

        return startTransition(() => navigateToInboxItem(previousInboxItem.id));
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();

        const nextIndex = activeIndex + 1;
        const nextInboxItem = filteredInboxItems.at(nextIndex);

        if (!nextInboxItem || activeIndex === filteredInboxItems.length) return;

        setActiveInboxItemId(nextInboxItem?.id);

        return startTransition(() => navigateToInboxItem(nextInboxItem.id));
      }

      if (event.key === 'Home') {
        const firstInboxItem = filteredInboxItems[0];

        if (!firstInboxItem) return;

        setActiveInboxItemId(firstInboxItem?.id);

        return startTransition(() => navigateToInboxItem(firstInboxItem.id));
      }

      if (event.key === 'End') {
        const lastIndex = filteredInboxItems.length - 1;
        const lastInboxItem = filteredInboxItems[lastIndex];

        if (!lastInboxItem) return;

        setActiveInboxItemId(lastInboxItem?.id);

        return startTransition(() => navigateToInboxItem(lastInboxItem.id));
      }

      if (activeIndex > -1 && event.key === 'Escape') {
        event.preventDefault();
        return closeInboxItem();
      }

      if (
        isCheckboxEnabled &&
        selectedInboxItemsIds.length > 0 &&
        ['Delete', 'Backspace'].includes(event.key)
      ) {
        event.preventDefault();
        return onRejectionMany();
      }

      if (
        status !== 'rejected' &&
        isAllowedRejectManyInboxItems &&
        activeIndex > -1 &&
        ['Delete', 'Backspace'].includes(event.key)
      ) {
        event.preventDefault();
        return onRejection();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    status,
    activeIndex,
    filteredInboxItems,
    isCheckboxEnabled,
    isAllowedRejectManyInboxItems,
    selectedInboxItemsIds,
    setActiveInboxItemId,
    navigateToInboxItem,
    onRejection,
    onRejectionMany,
    closeInboxItem,
  ]);

  return (
    <ContentWrapper fullWidth fixedHeight>
      <Grid container>
        <Grid xs={4} md={4} lg={4} xl={3} xxl={3} uxl={2}>
          <Box sx={content}>
            <Stack pl={12} pr={8} gap={4}>
              <InboxItemSelect
                inboxItems={filteredInboxItems}
                isLoading={isLoading}
              />
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height="44px" />
              ) : (
                <SearchInput
                  onChange={handleChangeSearch}
                  loading={isSearching}
                />
              )}
              {isCheckboxEnabled && (
                <InboxItemsCheckbox isLoading={isLoading} />
              )}
            </Stack>
            <Divider sx={{ mt: 5 }} />
            <InboxItemList
              isFiltering={!!quickFilter}
              inboxItems={filteredInboxItems}
              isLoading={isLoading}
              activeIndex={activeIndex}
              setActiveInboxItemId={setActiveInboxItemId}
              heightOffset={isCheckboxEnabled ? 120 : 80}
            />
          </Box>
        </Grid>
        <Grid xs={8} md={8} lg={8} xl={9} xxl={9} uxl={10} sx={details}>
          <InboxItemDetails inboxItems={filteredInboxItems} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}
