import { createContext, useContext, useState } from 'react';

import type {
  CalcAnchorPosition,
  ChartTableSyncContextProps,
  ChartTableSyncProviderProps,
} from './type';

const ChartTableSyncContext = createContext<
  undefined | ChartTableSyncContextProps
>(undefined);

export function calcAnchorPosition({
  target,
  currentTarget,
  container,
}: CalcAnchorPosition) {
  const elementToAlignWithRect = currentTarget.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();

  const xPosition = elementToAlignWithRect.left - container.scrollLeft;
  const positionCorrect = targetRect.left - xPosition;

  const LEFT_MARGIN = 190;
  const calcDiff = LEFT_MARGIN - elementToAlignWithRect.left;

  const DIVISOR_TO_CENTER = 2;
  const halfWidth = targetRect.width / DIVISOR_TO_CENTER;
  return elementToAlignWithRect.left < LEFT_MARGIN + halfWidth
    ? positionCorrect - calcDiff
    : positionCorrect + halfWidth;
}

export function mountLinkToClassName(date: string) {
  return `link-to-${date}`;
}

export default function ChartTableSyncProvider({
  children,
}: ChartTableSyncProviderProps) {
  const [syncedChartTableColumn, setSyncedValue] = useState('');

  function syncTableWithChart(event: React.MouseEvent, date: string) {
    const container = document.querySelector('.container-scroll-sync-js');
    const target = document.querySelector(`.${mountLinkToClassName(date)}`);

    if (!container || !target) return;

    const anchorPosition = calcAnchorPosition({
      target,
      currentTarget: event.currentTarget,
      container,
    });

    container.scroll({
      left: anchorPosition,
      behavior: 'smooth',
    });

    setSyncedValue(date);
  }

  return (
    <ChartTableSyncContext.Provider
      value={{
        syncTableWithChart,
        syncedChartTableColumn,
      }}
    >
      {children}
    </ChartTableSyncContext.Provider>
  );
}

export function useChartTableSyncContext() {
  const context = useContext(ChartTableSyncContext);

  if (!context) {
    throw new Error(
      'useChartTableSyncContext must be used within a ChartTableSyncProvider'
    );
  }

  return context;
}
