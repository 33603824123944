import { useState } from 'react';

import { Col, Row } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { ContentWrapper } from 'src/design-system/components';

import { Title } from 'src/ui';

import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import MonthMovements from 'src/features/dashboard/MonthMovements';
import CurrentMonthBankBalance from 'src/features/dashboard/CurrentMonthBankBalance';
import EditReceivableDrawer from 'src/features/receivables/EditReceivableDrawer/EditReceivableDrawer';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer';
import EditBillDrawer from 'src/features/bills-to-pay/EditBillDrawer';
import EntriesOverdueDataGrid from 'src/features/entries/EntriesOverdueDataGrid';

function Dashboard() {
  const { companyId, organizationId } = useCompanyParams();

  const [billId, setBillId] = useState<string>();
  const [receivableId, setReceivableId] = useState<string>();
  const [financialEntryId, setFinancialEntryId] = useState<string>();

  function handleManualReconciliate(financialEntry: FinancialEntry) {
    setReceivableId(undefined);
    setBillId(undefined);
    setFinancialEntryId(financialEntry.id);
  }

  return (
    <ContentWrapper gap={8}>
      {receivableId && (
        <EditReceivableDrawer
          id={receivableId}
          onClose={() => setReceivableId(undefined)}
          onManualReconciliate={handleManualReconciliate}
        />
      )}

      {billId && (
        <EditBillDrawer
          billId={billId}
          onClose={() => setBillId(undefined)}
          onManualReconciliate={handleManualReconciliate}
        />
      )}

      {financialEntryId && (
        <EditFinancialEntryDrawer
          financialEntryId={financialEntryId}
          onClose={() => setFinancialEntryId(undefined)}
        />
      )}

      <Row gutter={[48, 32]}>
        <Col span={24}>
          <Title icon={<DashboardOutlined />}>Resumo - Empresa atual</Title>
        </Col>

        <Col xs={24} md={12}>
          <MonthMovements />
        </Col>

        <Col xs={24} md={12}>
          <CurrentMonthBankBalance />
        </Col>
      </Row>
      <EntriesOverdueDataGrid
        companyId={companyId}
        organizationId={organizationId}
      />
    </ContentWrapper>
  );
}

export default Dashboard;
