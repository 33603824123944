import { Stack } from '@mui/material';
import compact from 'lodash/compact';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useBuildActions } from 'src/features/bills-to-pay/Actions/useBuildActions';

import ActionButton from './ActionButton';
import DropdownActions from './DropdownActions';

type Props = {
  bill: BillPayable;
  onSuccess?: () => void;
};

export default function HeaderActions({ bill, onSuccess }: Props) {
  const {
    restoreAction,
    sendToApprovedAction,
    sendToReprovedAction,
    sendToWaitingApprovalAction,
    sendToWaitingPaymentAction,
    undoWaitingApprovalAction,
    undoApprovedAction,
    undoWaitingPaymentAction,
    markAsFinancialAction,
    copyPublicIdAction,
  } = useBuildActions(bill, onSuccess);

  const actions = compact([
    restoreAction,
    sendToApprovedAction,
    sendToReprovedAction,
    sendToWaitingApprovalAction,
    sendToWaitingPaymentAction,
    undoWaitingApprovalAction,
    undoApprovedAction,
    undoWaitingPaymentAction,
    markAsFinancialAction,
    copyPublicIdAction,
  ]);

  if (actions.length === 0) return null;

  const [firstAction, secondAction, thirdAction, ...dropDownActions] = actions;

  const renderAsDropDown = Boolean(dropDownActions.length);

  return (
    <Stack flexDirection="row" gap={2}>
      <ActionButton action={firstAction} />
      <ActionButton action={secondAction} />

      {renderAsDropDown ? (
        <DropdownActions actions={[thirdAction, ...dropDownActions]} />
      ) : (
        <ActionButton action={thirdAction} iconOnly />
      )}
    </Stack>
  );
}
