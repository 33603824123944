import cn from 'classnames';
import { Box } from '@mui/material';

import styles from './ContentWrapper.module.scss';

type ContentWrapperProps = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  gap?: number;
  fixedHeight?: boolean;
};

export default function ContentWrapper({
  children,
  className,
  fullWidth,
  gap = 0,
  fixedHeight = false,
}: ContentWrapperProps) {
  return (
    <Box
      data-testid="content-wrapper"
      gap={gap}
      className={cn(styles.container, className, {
        [styles.fullWidth]: fullWidth,
        [styles.fixedHeight]: fixedHeight,
      })}
    >
      {children}
    </Box>
  );
}
