import t from 'zod';

export const REQUIRED_MESSAGE_ISSUER =
  'Por favor, informe o número da agência ou cooperativa';
export const REQUIRED_MESSAGE_ACCOUNT_NUMBER =
  'Por favor, informe o número da conta.';
export const REQUIRED_MESSAGE_DOCUMENT = 'Por favor, informe o CNPJ.';
export const REQUIRED_CLIENT_ID = 'Por favor, informe o Client ID.';
export const REQUIRED_MESSAGE_TERM =
  'Por favor, confirme que já possui as credenciais da API.';
export const REQUIRED_MESSAGE_API_TERM =
  'Por favor, confirme que habilitou a API.';

export const SicoobAutoPaymentTermResolver = t.object({
  term: t.literal(true, {
    errorMap: () => ({ message: REQUIRED_MESSAGE_TERM }),
  }),
});

export const SicoobAutoPaymentAPITermResolver = t.object({
  term: t.literal(true, {
    errorMap: () => ({ message: REQUIRED_MESSAGE_API_TERM }),
  }),
});

export const SicoobAutoPaymentResolver = t.object({
  document: t
    .string({
      required_error: REQUIRED_MESSAGE_DOCUMENT,
    })
    .min(1, { message: REQUIRED_MESSAGE_DOCUMENT }),
  issuer: t
    .string({
      required_error: REQUIRED_MESSAGE_ISSUER,
    })
    .min(1, { message: REQUIRED_MESSAGE_ISSUER }),
  accountNumber: t
    .string({
      required_error: REQUIRED_MESSAGE_ACCOUNT_NUMBER,
    })
    .min(1, { message: REQUIRED_MESSAGE_ACCOUNT_NUMBER }),
});

export const SicoobAutoPaymentClientIDResolver = t.object({
  clientId: t
    .string({
      required_error: REQUIRED_CLIENT_ID,
    })
    .min(1, { message: REQUIRED_CLIENT_ID }),
});
