import { isEnabledLDFeature, useFlags } from 'src/libs/launchDarkly';

export enum Feature {
  AI_AUTOFILL_SUGGESTIONS = 'aiAutofillSuggestions',
  BB_PAYMENT_CONNECTOR = 'bbPaymentConnector',
  BILL_PAGE_DATE_FILTER = 'billPageDateFilter',
  EXAMPLE = 'example',
  FINBOT = 'finbot',
  MARK_BATCH_RELEASES = 'markBatchReleases',
  CLONE_ENTRIES = 'cloneEntries',
  NEW_NOTA_FISCAL_SETUP = 'newNotaFiscalSetup',
  NOTA_FISCAL_CERTIFICATION_ATTACH = 'certificationAttach',
  SICOOB_PAYMENT_CONNECTOR = 'sicoobPaymentConnector',
  OVERVIEW_PROJECTED = 'overviewProjected',
  NEW_CATEGORIES = 'newCategories',
  IMPORT_CLASSIFICATIONS = 'importClassificationsXlsx',
}

type OpenFlags = Record<Feature, boolean>;

export const openFlags: Partial<OpenFlags> = {
  [Feature.EXAMPLE]: true,
  [Feature.NEW_NOTA_FISCAL_SETUP]: true,
};

export function useExternalFeatureFlag(feature: Feature) {
  const flags = useFlags();

  const isEnabled = isEnabledLDFeature(feature, flags);

  return { isEnabled };
}
