import { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import type { ZoomData, ZoomOptions } from '@mui/x-charts-pro';

import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';

import styles from '../OverviewChart.module.scss';

type Props = {
  data: OverviewCashFlow[];
  isLoading: boolean;
  axisId: string;
};

const GRAFIC_X_AXIOS_PERCENTAGE = 60;
const MAX_DATA_LENGTH = 60;

//between 1 ... 100
function getMax(dataLength: number) {
  if (dataLength < MAX_DATA_LENGTH) return 100;

  return Math.ceil(GRAFIC_X_AXIOS_PERCENTAGE / (dataLength / 100));
}

function getZoom(axisId: string, paramsDataLength: number) {
  return [
    {
      axisId: axisId,
      start: 0,
      end: getMax(paramsDataLength),
    },
  ];
}

function getEmptyClasseNames(emptyOrLoading: boolean) {
  if (emptyOrLoading) {
    return {
      line: styles.tick,
      tick: styles.tick,
      tickLabel: styles.label,
    };
  }

  return undefined;
}

const HIDE_MESSAGE_AFTER = 1000;
const handleScrollEnd = debounce(
  (
    setShowOverlay: (overlay: boolean) => void,
    setBlockZoom: (blockZoom: boolean) => void
  ) => {
    setShowOverlay(false);
    setBlockZoom(true);
  },
  HIDE_MESSAGE_AFTER
);

export function useChartConfig({ data, isLoading, axisId }: Props) {
  const chartRef = useRef<HTMLDivElement>(null);
  const [blockZoom, setBlockZoom] = useState<boolean>(true);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const [chartDate, setChartData] = useState(data);

  const [zoomOptions, setZoomOptions] = useState<ZoomOptions>({
    maxSpan: 100,
  });
  const [zoom, setZoom] = useState<ZoomData[]>(getZoom(axisId, 0));

  const [loading, setLoading] = useState(isLoading);

  function reset() {
    setZoom(getZoom(axisId, 0));
    setChartData([]);
    setZoomOptions({ maxSpan: 100 });
    setLoading(true);
  }

  useEffect(() => {
    if (isLoading) {
      reset();
      return;
    } else {
      setZoom(getZoom(axisId, data.length));
      setChartData(data);
      setZoomOptions({ maxSpan: getMax(data.length) });
      setLoading(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, axisId]);

  useEffect(() => {
    function handleScroll(event: WheelEvent) {
      const holdingKey = event.metaKey || event.ctrlKey;

      setShowOverlay(!holdingKey);

      if (holdingKey) {
        event.preventDefault();
        setBlockZoom(false);
      } else {
        setBlockZoom(true);
      }

      handleScrollEnd(setShowOverlay, setBlockZoom);
    }

    const chart = chartRef.current;
    chart?.addEventListener('wheel', handleScroll);

    return () => {
      chart?.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const isEmpty = chartDate.length === 0;
  const emptyOrLoading = isEmpty || loading;

  function handleZoom(
    newZoom: ZoomData[] | ((zoomData: ZoomData[]) => ZoomData[])
  ) {
    if (showOverlay && blockZoom) return;

    setZoom(newZoom);
  }

  return {
    chartDate,
    zoom,
    zoomOptions,
    loading,
    emptyOrLoading,
    isEmpty,
    axisClasses: getEmptyClasseNames(emptyOrLoading),
    reset,
    onZoomChange: handleZoom,
    blockZoom: showOverlay,
    chartRef,
  };
}
