import { LoadingButton } from '@mui/lab';
import type { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';
import { Typography } from 'src/design-system/components';
import { AttachmentIcon } from 'src/mui/_icons';

import { UploadDragger } from 'src/ui';

import { validateBeforeUpload } from 'src/libs/finbits/Spreadsheet/validations';

import { CLASSIFICATION_SPREADSHEET_TEMPLATE } from '../../enum';

import styles from './UploadContent.module.scss';

type Props = {
  onImportRequest: (file: RcFile) => void;
  isUploading: boolean;
};

export default function UploadContent({ onImportRequest, isUploading }: Props) {
  return (
    <>
      <Typography marginBottom={4}>
        Faça a importação de sua planilha de etiquetas selecionando ou
        arrastando o arquivo .xlsx para a área abaixo.
      </Typography>

      <Typography marginBottom={4}>
        Disponibilizamos um modelo de planilha para que não ocorram erros de
        dados e/ou formatação durante a importação.
      </Typography>

      <Typography marginBottom={4}>Faça o download no link abaixo.</Typography>

      <Typography marginBottom={4}>
        <a
          href={CLASSIFICATION_SPREADSHEET_TEMPLATE}
          className={styles.download}
          download
        >
          <AttachmentIcon className={styles.icon} />
          planilha-etiquetas-finbits.xlsx
        </a>
      </Typography>

      <UploadDragger
        data-testid="xlsx-upload"
        accept=".xlsx"
        multiple={false}
        showUploadList={false}
        disabled={isUploading}
        customRequest={({ file }: UploadRequestOption) =>
          onImportRequest(file as RcFile)
        }
        beforeUpload={validateBeforeUpload}
      >
        <Typography className={styles.textUpload}>
          Selecione ou arraste a planilha
          <br /> (arquivo .xlsx) para esta área.
        </Typography>

        <LoadingButton
          variant="contained"
          color="primary"
          loading={isUploading}
        >
          Selecione o arquivo
        </LoadingButton>
      </UploadDragger>
    </>
  );
}
