import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';

export default function OtherCategories() {
  return (
    <Box>
      <Typography>Outras Categorias</Typography>
    </Box>
  );
}
