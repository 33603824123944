import { Col, Row, Typography } from 'antd';
import { TagIcon } from 'src/mui/_icons';
import { ContentWrapper } from 'src/design-system/components';

import { Title } from 'src/ui';

import ClassificationsList from 'src/features/classifications/ClassificationList';
import CreateClassificationButton from 'src/features/classifications/CreateClassificationButton';
import ImportClassificationsButton from 'src/features/classifications/ImportClassificationsButton';

import styles from './Classifications.module.less';

export default function Classifications() {
  return (
    <ContentWrapper>
      <Row gutter={[10, 32]} align="top" justify="space-between">
        <Col span={24}>
          <Title icon={<TagIcon />}>Etiquetas</Title>
        </Col>

        <Col>
          <Typography.Text className={styles.paragraph}>
            Aqui você cria e edita as etiquetas, dentro de suas classificações.
          </Typography.Text>
        </Col>
        <Col>
          <Row gutter={[8, 32]}>
            <Col>
              <ImportClassificationsButton />
            </Col>

            <Col>
              <CreateClassificationButton />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <ClassificationsList />
        </Col>
      </Row>
    </ContentWrapper>
  );
}
