import { useState } from 'react';

import { Button, Row } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { ContentWrapper } from 'src/design-system/components';

import { PaymentIcon, Title, useOpenPortal } from 'src/ui';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { useNotasFiscais } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import EnableNotaFiscalDrawer from 'src/features/nota-fiscal/EnableNotaFiscalDrawer';
import EditSetupNotaFiscalDrawer from 'src/features/nota-fiscal/EditSetupNotaFiscalDrawer';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';
import NotaFiscalTable from 'src/features/nota-fiscal/NotaFiscalTable';
import NotaFiscalEmptyState from 'src/features/nota-fiscal/NotaFiscalEmptyState';
import EditNotaFiscalSetupModal from 'src/features/nota-fiscal/EditNotaFiscalSetupModal/EditNotaFiscalSetupModal';

import styles from './NotaFiscal.module.less';

function NotaFiscal() {
  const { isEnabled: isEnabledNewNotaFiscalSetup } = useExternalFeatureFlag(
    Feature.NEW_NOTA_FISCAL_SETUP
  );

  const [
    isEditSetupNotaFiscalDrawerVisible,
    setIsEditSetupNotaFiscalDrawerVisible,
  ] = useState(false);

  const openPortal = useOpenPortal();

  const [isEnableNotaFiscalDrawerVisible, setIsEnableNotaFiscalDrawerVisible] =
    useState(false);

  const [isCreateNotaFiscalDrawerVisible, setIsCreateNotaFiscalDrawerVisible] =
    useState(false);

  const { status: connectionStatus, isLoading: isConnectionLoading } =
    useNotaFiscalConnection();

  const isActive = connectionStatus === 'active';
  const canUpdateNotaFiscalSetup =
    isActive || connectionStatus === 'activating';

  const { organizationId, companyId } = useCompanyParams();
  const { notasFiscais, isLoading: isNotaFiscalLoading } = useNotasFiscais({
    organizationId,
    companyId,
  });

  return (
    <>
      {isEditSetupNotaFiscalDrawerVisible && (
        <EditSetupNotaFiscalDrawer
          onClose={() => setIsEditSetupNotaFiscalDrawerVisible(false)}
        />
      )}

      {isEnableNotaFiscalDrawerVisible && (
        <EnableNotaFiscalDrawer
          onClose={() => setIsEnableNotaFiscalDrawerVisible(false)}
          openCreateNotaFiscalDrawer={() =>
            setIsCreateNotaFiscalDrawerVisible(true)
          }
        />
      )}

      {isCreateNotaFiscalDrawerVisible && (
        <CreateNotaFiscalDrawer
          onClose={() => setIsCreateNotaFiscalDrawerVisible(false)}
        />
      )}

      <ContentWrapper gap={4}>
        <Row justify="space-between" align="top">
          <Title icon={<PaymentIcon />}>Notas fiscais</Title>
          <div className={styles.actionsPageWrapper}>
            {canUpdateNotaFiscalSetup && (
              <WithAllowedPermission
                permissions={{
                  resource: 'notaFiscalSetup',
                  action: ['create', 'update'],
                }}
              >
                <>
                  {isEnabledNewNotaFiscalSetup && (
                    <Button
                      aria-label="Configurações (MUI)"
                      title="Configurações"
                      icon={<SettingOutlined />}
                      onClick={() => openPortal(EditNotaFiscalSetupModal)}
                    >
                      Configuração (MUI)
                    </Button>
                  )}
                  <Button
                    aria-label="Configurações"
                    title="Configurações"
                    icon={<SettingOutlined />}
                    onClick={() => setIsEditSetupNotaFiscalDrawerVisible(true)}
                  />
                </>
              </WithAllowedPermission>
            )}
            {isActive && (
              <WithAllowedPermission
                permissions={{
                  resource: 'notasFiscais',
                  action: 'create',
                }}
              >
                <Button
                  type="primary"
                  icon={<PaymentIcon color="white" />}
                  onClick={() => setIsCreateNotaFiscalDrawerVisible(true)}
                >
                  Emitir nota fiscal
                </Button>
              </WithAllowedPermission>
            )}
          </div>
        </Row>

        {notasFiscais.length ? (
          <NotaFiscalTable
            isLoading={isNotaFiscalLoading}
            notasFiscais={notasFiscais}
          />
        ) : (
          <NotaFiscalEmptyState
            connectionStatus={connectionStatus}
            isConnectionLoading={isConnectionLoading || isNotaFiscalLoading}
            onOpenEnableDrawer={() => setIsEnableNotaFiscalDrawerVisible(true)}
            onOpenEditSetupDrawer={() =>
              setIsEditSetupNotaFiscalDrawerVisible(true)
            }
            onOpenCreateDrawer={() => setIsCreateNotaFiscalDrawerVisible(true)}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default NotaFiscal;
