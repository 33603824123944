import { Button } from '@mui/material';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateClassificationModal from 'src/features/classifications/CreateClassificationModal';

export default function CreateClassificationButton() {
  const openPortal = useOpenPortal();

  function openCreateClassificationModal() {
    openPortal(CreateClassificationModal);
  }

  return (
    <WithAllowedPermission
      permissions={{ resource: 'classifications', action: 'create' }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={openCreateClassificationModal}
      >
        Nova Classificação
      </Button>
    </WithAllowedPermission>
  );
}
