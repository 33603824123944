import { Box, Button, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from 'src/design-system/components';
import {
  BankIcon,
  FileAttachmentIcon,
  LinkHorizontalIcon,
  MailIcon,
  MessageChatCircleIcon,
  UploadCloudIcon,
} from 'src/mui/_icons';
import Carousel from 'src/mui/Carousel';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Badge, CopyButton, Tooltip } from 'src/mui';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useOpenBankingConnectionFeatures } from 'src/libs/finbits/Bank/OpenBanking';
import { PaymentConnectionScopes } from 'src/libs/finbits/Bank/OpenBanking/enum';
import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { formatList } from 'src/libs/finbits/List';
import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { e164Format } from 'src/libs/finbits/Organization/PhoneNumber';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';

import AutoPaymentBanner from 'src/features/open-banking/AutoPayment/AutoPaymentBanner';

import CreateUploadButton from './CreateUploadButton';
import DotIcon from './DotIcon';
import InformationCard from './InformationCard';
import style from './Informations.module.scss';
import WhatsAppInfo from './WhatsAppInfo';

const phoneNumber = import.meta.env.VITE_WHATSAPP_NUMBER ?? '';

export default function Informations() {
  const { organizationId, companyId } = useCompanyParams();
  const { company } = useCompany(
    { organizationId, companyId },
    { staleTime: ONE_HOUR_IN_MS }
  );
  const email = company?.inboxEmailAddress ?? '';
  const url = `${window.location.origin}/p/${company?.publicId}`;

  const urlAccountSettings = generateCompanyPath(RouteKey.ACCOUNTS, {
    companyId,
    organizationId,
  });

  const { accounts } = useAccounts({ companyId, organizationId });

  const accountsWithPaymentConnection = accounts.filter(
    (account) => account.canAssociatePaymentConnection
  );

  const accountIds = accountsWithPaymentConnection.map((account) => account.id);

  const { features } = useOpenBankingConnectionFeatures({
    companyId,
    organizationId,
    accountIds: accountIds,
  });

  const hasDDA = accountsWithPaymentConnection
    .map((account) => ({
      ...account,
      features: features?.find((feature) => feature.accountId === account.id),
    }))
    .filter((account) => {
      return (
        account.routingNumber === RoutingNumber.BTG &&
        account.features?.paymentConnection?.scopes?.includes(
          PaymentConnectionScopes.DDA
        )
      );
    })
    .map((item) => `${item.name} (${item.accountNumber})`);

  const accountsTooltip = `${pluralize(
    hasDDA.length,
    'Conta',
    'Contas'
  )}: ${formatList(hasDDA)}`;

  function openWhatsApp() {
    window.open(`https://wa.me/${e164Format(phoneNumber)}`, '_blank');
  }

  return (
    <>
      <Carousel autoplay={true} showBullets={true} autoPlaySpeed={15000}>
        <AutoPaymentBanner big />
      </Carousel>

      <Box className={style.informations}>
        <Typography variant="text-md" component="h1" fontWeight="600">
          Nenhuma entrada selecionada
        </Typography>

        <Typography className={style.textSecondary}>
          Selecione uma entrada ou verifique as formas de receber entradas.
        </Typography>

        <Grid container spacing={6}>
          <InformationCard
            icon={<MessageChatCircleIcon color="inherit" />}
            title="Envie por WhatsApp"
            content={
              <>
                <Typography className={style.contentCard}>
                  O número <b>(11) 4040-7770</b> é o WhatsApp do Finbits. Siga
                  os passos abaixo para enviar seus recibos, notas, comprovantes
                  para a plataforma diretamente por lá.
                </Typography>

                <WhatsAppInfo />
              </>
            }
            actions={
              <>
                <CopyButton
                  clipboard={e164Format(phoneNumber) ?? ''}
                  title="Número copiado"
                  className={style.secondaryButton}
                >
                  Copiar Número
                </CopyButton>

                <Button
                  size="small"
                  className={style.whatsAppButton}
                  onClick={openWhatsApp}
                >
                  Abrir no WhatsApp
                </Button>
              </>
            }
          />

          <InformationCard
            icon={<MailIcon color="inherit" />}
            title="E-mail Exclusivo"
            content={
              <>
                <Typography
                  className={style.contentCard}
                  color="text.secondary"
                  ellipsis
                >
                  Você pode receber suas entradas através do e-mail exclusivo{' '}
                  <Link href={`mailto:${email}`}>{email}</Link>
                </Typography>
                <Typography
                  className={style.contentCard}
                  color="text.secondary"
                  ellipsis
                >
                  Os anexos enviados para este email precisa ter o tamanho igual
                  ou menor a
                  <Tooltip
                    title="Se precisar enviar um conjunto de anexos com tamanho maior, sugerimos fazer mais de um envio de email com um conjunto de anexos menor a 20 MB."
                    className={style.tooltipHover}
                  >
                    <b>20 MB</b>
                  </Tooltip>
                  no total.
                </Typography>
              </>
            }
            actions={
              <CopyButton
                clipboard={email}
                title="E-mail copiado"
                className={style.secondaryButton}
              >
                Copiar E-mail
              </CopyButton>
            }
          />

          <InformationCard
            icon={<FileAttachmentIcon color="inherit" />}
            title="Formulário Externo"
            content={
              <Typography className={style.contentCard}>
                Mande este link{' '}
                <Link color="text.secondary" href={url} target="_blank">
                  {url}
                </Link>{' '}
                para quem você deseja que envie entradas totalmente
                contextualizadas.
              </Typography>
            }
            actions={
              <CopyButton
                clipboard={url}
                title="Link copiado"
                className={style.secondaryButton}
              >
                Copiar Link
              </CopyButton>
            }
          />

          <InformationCard
            icon={<BankIcon color="inherit" />}
            title="DDA - Leitura automática de boletos"
            content={
              <>
                <Typography className={style.contentCard}>
                  Receba seus boletos na caixa de entrada de forma automática
                  via DDA (Débito Direto Autorizado).
                </Typography>

                <Typography className={style.contentCard}>
                  Disponível para o <strong>Banco BTG.</strong>
                </Typography>
              </>
            }
            actions={
              <>
                <Typography
                  variant="text-lg"
                  fontWeight="600"
                  className={style.connection}
                >
                  <DotIcon
                    fontSize="large"
                    color={hasDDA.length ? 'success' : 'warning'}
                  />
                  DDA {hasDDA.length ? 'Ativado' : 'Desativado'}
                  {!!hasDDA.length && (
                    <Tooltip
                      title={accountsTooltip}
                      enterDelay={100}
                      placement="top"
                    >
                      <Badge
                        badgeContent={hasDDA.length}
                        classes={{ badge: style.badge }}
                        aria-label="badge-dda"
                      />
                    </Tooltip>
                  )}
                </Typography>

                <Button
                  href={urlAccountSettings}
                  className={style.btnAccount}
                  startIcon={<LinkHorizontalIcon />}
                >
                  Conectar Conta
                </Button>
              </>
            }
          />

          <InformationCard
            icon={<UploadCloudIcon color="inherit" />}
            title="Upload Manual"
            content={
              <Typography className={style.contentCard}>
                Você pode subir o seu anexo manualmente clicando no link abaixo.
              </Typography>
            }
            actions={<CreateUploadButton />}
          />
        </Grid>
      </Box>
    </>
  );
}
