import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { FormField } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';
import cn from 'classnames';

import { SicoobAutoPaymentAPITermResolver } from 'src/libs/finbits/Bank/Sicoob/validations';

import AutoPaymentCard from 'src/features/open-banking/AutoPayment/AutoPaymentModal/AutoPaymentCard';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './RequestApiEnablementStep.module.scss';

export default function RequestApiEnablementStep({
  onNext,
  onPrev,
}: StepperContentProps) {
  const form = useForm({
    resolver: zodResolver(SicoobAutoPaymentAPITermResolver),
    mode: 'onChange',
    defaultValues: {
      term: false,
    },
  });

  async function handleSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (isValid) {
      onNext?.(event);
    }
  }

  return (
    <>
      <Box>
        <AutoPaymentCard>
          <Typography variant="text-sm" fontWeight="400" color="grey.600">
            Utilize o canal de atendimento padrão da cooperativa para fazer a
            seguinte solicitação ao seu gerente Sicoob:
          </Typography>

          <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Typography
                variant="text-sm"
                color="grey.600"
                fontWeight={600}
                marginBottom={4}
              >
                Habilitar Pagamento API
              </Typography>
            </CardContent>
          </Card>
        </AutoPaymentCard>
      </Box>

      <Card
        variant="outlined"
        className={cn(styles.termCard, {
          [styles.error]: form.formState.errors.term,
        })}
      >
        <CardContent className={styles.cardContent}>
          <FormProvider {...form}>
            <FormField name="term" showErrors={false}>
              {(field) => (
                <FormControlLabel
                  {...field}
                  control={
                    <Checkbox
                      size="small"
                      className={cn({
                        [styles.error]: form.formState.errors.term,
                      })}
                    />
                  }
                  label="A aplicação Pagamento API foi habilitada pela cooperativa"
                  className={styles.formLabel}
                />
              )}
            </FormField>
          </FormProvider>

          <Typography
            variant="text-sm"
            color="grey.600"
            fontWeight={400}
            marginBottom={4}
          >
            Confirme que a habilitação da API foi realizada.
          </Typography>
        </CardContent>
      </Card>

      {form.formState.errors.term && (
        <Typography
          variant="text-sm"
          fontWeight={400}
          marginTop="5px"
          className={styles.error}
        >
          {form.formState.errors.term.message}
        </Typography>
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="48px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Continuar
        </Button>
      </Box>
    </>
  );
}
