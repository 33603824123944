import { useState } from 'react';

import { Button, Col, Row, Space } from 'antd';
import { ApartmentOutlined, PlusOutlined } from '@ant-design/icons';
import { ContentWrapper } from 'src/design-system/components';

import { Title } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateCompanyModal from 'src/features/companies/CreateCompanyModal';
import CompaniesBalances from 'src/features/companies-balances/CompaniesBalances';

export default function Balances() {
  const [isCreateCompanyModalVisible, setIsCreateCompanyModalVisible] =
    useState(false);

  return (
    <>
      {isCreateCompanyModalVisible && (
        <CreateCompanyModal
          onClose={() => setIsCreateCompanyModalVisible(false)}
        />
      )}

      <ContentWrapper>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Title icon={<ApartmentOutlined />}>
                Resumo - Todas as empresas
              </Title>
              <Space size={8}>
                <WithAllowedPermission
                  permissions={{
                    action: 'create',
                    resource: 'companies',
                    scope: 'organization',
                  }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setIsCreateCompanyModalVisible(true)}
                  >
                    Adicionar empresa
                  </Button>
                </WithAllowedPermission>
              </Space>
            </Row>
          </Col>
          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'companiesBalances',
              scope: 'organization',
            }}
          >
            <Col span={24}>
              <CompaniesBalances />
            </Col>
          </WithAllowedPermission>
        </Row>
      </ContentWrapper>
    </>
  );
}
