import type {
  Components,
  InputBaseProps,
  SimplePaletteColorOptions,
} from '@mui/material';
import { inputBaseClasses, outlinedInputClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiInputBase({
  palette,
  shadows,
}: ThemeConfig): Components['MuiInputBase'] {
  const { light } = palette.primary! as Partial<SimplePaletteColorOptions>;

  return {
    defaultProps: {
      disableInjectingGlobalStyles: true,
    },
    styleOverrides: {
      root: ({ ownerState }: { ownerState: InputBaseProps }) => ({
        ...(ownerState.size === 'medium' && {
          [`& .${inputBaseClasses.input}`]: {
            boxSizing: 'border-box',
            height: '44px',
            padding: '12px 14px',
          },
        }),

        [`.${outlinedInputClasses.notchedOutline}`]: {
          transition: 'all 0.3s ease-in-out',
        },

        [`&.${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
          {
            border: `1px solid ${light} !important`,
            boxShadow: `0px 0px 0px 4px ${light}, ${shadows[1]}`,
          },

        [`&.${outlinedInputClasses.root}.${inputBaseClasses.formControl} .${inputBaseClasses.input}`]:
          {
            '&::-webkit-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            },
            '&::-moz-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // Firefox 19+
            '&:-ms-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // IE11
            '&::-ms-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // Edge
            '&:focus::-webkit-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            },
            '&:focus::-moz-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // Firefox 19+
            '&:focus:-ms-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // IE11
            '&:focus::-ms-input-placeholder': {
              color: palette.text?.secondary,
              opacity: '1 !important',
            }, // Edge
          },
      }),
    },
  };
}
