import { useCallback } from 'react';

import { Typography } from 'src/design-system/components';
import { AlertCircleIcon, ClockRewindIcon, TrashIcon } from 'src/mui/_icons';

import { useOpenConfirmDialog } from 'src/ui';

import { pluralize } from 'src/libs/finbits/Pluralize';

type Props = {
  onConfirm: () => void;
};

type UseOpenConfirmMarkAsDoneProps = Props & {
  selectedCount: number;
};

export function useOpenConfirmLeaveFormDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <AlertCircleIcon />,
        title: 'Tem certeza que deseja sair?',
        content: 'Ao sair sem salvar, as alterações serão perdidas.',
        confirmText: 'Sim, quero sair',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmDeletionDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <TrashIcon />,
        title: 'Tem certeza que deseja excluir?',
        content: 'As informações serão excluídas permanentemente.',
        confirmText: 'Sim, quero excluir',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmSoftDeleteDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <TrashIcon />,
        title: 'Excluir',
        content: (
          <Typography>
            Ao excluir, o item será movido para a aba de <b>excluídos</b>, onde
            o pagamento correspondente não será realizado nem exibido no seu
            fluxo de caixa.
          </Typography>
        ),
        confirmText: 'Excluir',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmUndoConciliationDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <ClockRewindIcon />,
        title: 'Tem certeza que deseja desfazer?',
        content:
          'O lançamento que foi conciliado será restaurado e voltará para a lista de programados.',
        confirmText: 'Sim, quero desfazer',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmRestore() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'warning',
        icon: <ClockRewindIcon />,
        title: 'Restaurar',
        content: (
          <Typography>
            Ao restaurar, o item volta para contas a pagar.
          </Typography>
        ),
        confirmText: 'Restaurar',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmMarkAsDone() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    ({ selectedCount, ...props }: UseOpenConfirmMarkAsDoneProps) => {
      const titleDialog = pluralize(
        selectedCount,
        'Confirmar alteração da situação do lançamento selecionado?',
        'Confirmar alteração da situação dos lançamentos selecionados?'
      );

      openConfirmDialog({
        variant: 'error',
        icon: <AlertCircleIcon />,
        title: titleDialog,
        content: (
          <>
            <Typography marginTop={4} color="grey.600">
              Você selecionou{' '}
              <strong>
                {pluralize(
                  selectedCount,
                  `${selectedCount} lançamento`,
                  `${selectedCount} lançamentos`
                )}
              </strong>{' '}
              {pluralize(
                selectedCount,
                `para ser marcado como realizado.`,
                `para serem marcados como realizados.`
              )}
            </Typography>

            {selectedCount > 1 && (
              <Typography marginTop={4} color="grey.600">
                Após a confirmação, esta ação só poderá ser desfeita para cada
                item separadamente.
              </Typography>
            )}
          </>
        ),
        confirmText: 'Confirmar',
        cancelText: 'Voltar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}
