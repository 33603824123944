import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { FormField, Input } from 'src/mui/_scss';
import Grid from '@mui/material/Unstable_Grid2';

import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import {
  SicoobAutoPaymentResolver,
  useCreateSicoobConsentment,
} from 'src/libs/finbits/Bank/Sicoob';

import AutoPaymentCard from 'src/features/open-banking/AutoPayment/AutoPaymentModal/AutoPaymentCard';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './ReviewAccountInfoStep.module.scss';

type Props = StepperContentProps & {
  clientId: string;
};

export default function ReviewAccountInfoStep({
  onNext,
  onPrev,
  account,
  clientId,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ organizationId, companyId });

  const { createSicoobConsentment, isLoading, isError } =
    useCreateSicoobConsentment();
  const form = useForm({
    resolver: zodResolver(SicoobAutoPaymentResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientId: clientId,
      document: company?.document || '',
      issuer: account?.branchNumber || '',
      accountNumber: account?.accountNumber || '',
    },
  });

  useEffect(() => {
    form.setValue('clientId', clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  async function handleSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (isValid) {
      const { clientId, document, issuer, accountNumber } = form.getValues();

      createSicoobConsentment(
        {
          organizationId,
          companyId,
          accountId: account!!.id,
          clientId,
          document,
          issuer,
          accountNumber,
        },
        {
          onSuccess: () => {
            onNext?.(event);
          },
        }
      );
    }
  }

  return (
    <>
      {isError && (
        <Alert severity="error" variant="standard" className={styles.errorCard}>
          <AlertTitle>
            A conexão não pode ser concluída com os dados informados.
          </AlertTitle>
          Por favor, verifique se preencheu corretamente as credenciais
          solicitadas e tente novamente.
        </Alert>
      )}

      <AutoPaymentCard>
        <Typography color="secondary" variant="text-sm" fontWeight="400">
          Por favor, revise os dados da conta que deseja conectar:
        </Typography>

        <Grid container spacing={3} className={styles.grid}>
          <FormProvider {...form}>
            <Grid xs={4}>
              <FormField name="issuer" label="Agência / Cooperativa">
                {(field) => <Input {...field} />}
              </FormField>
            </Grid>

            <Grid xs={4}>
              <FormField name="accountNumber" label="Conta">
                {(field) => <Input {...field} />}
              </FormField>
            </Grid>

            <Grid xs={4}>
              <FormField name="document" label="CNPJ">
                {(field) => <Input {...field} />}
              </FormField>
            </Grid>
          </FormProvider>
        </Grid>
      </AutoPaymentCard>

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="48px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Concluir conexão
        </Button>
      </Box>
    </>
  );
}
