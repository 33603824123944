import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { GlobalStyles } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { createTheme } from 'src/theme';

import {
  getMUIThemeModeItem,
  setMUIThemeModeItem,
} from 'src/libs/finbits/Storage';

import LightTheme from './themes/LightTheme';

type Props = {
  children: ReactNode;
};

export function ThemeProvider({ children }: Props) {
  const muiTheme = createTheme('light');

  // TODO: Remove once all users are using light mode.
  useEffect(() => {
    if (getMUIThemeModeItem() === 'dark') {
      setMUIThemeModeItem('light');
      window.location.reload();
    }
  }, []);

  return (
    <CssVarsProvider theme={muiTheme}>
      <GlobalStyles
        styles={{
          '@keyframes mui-auto-fill': { from: { display: 'block' } },
          '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
        }}
      />
      <LightTheme />
      {children}
    </CssVarsProvider>
  );
}
