import { Button, Stack } from '@mui/material';
import { ContentWrapper, Typography } from 'src/design-system/components';

import CategoriesPageTabs from './Tabs';
import styles from './Categories.module.scss';

export default function Categories() {
  return (
    <ContentWrapper>
      <Stack className={styles.header}>
        <Typography variant="text-lg" fontWeight="600" component="h2">
          Categorias
        </Typography>

        <Button
          onClick={() => console.log('not implemented')}
          variant="contained"
          color="primary"
        >
          Criar categoria
        </Button>
      </Stack>

      <CategoriesPageTabs />
    </ContentWrapper>
  );
}
