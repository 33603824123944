import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import TermsOfUseAlert from 'src/features/terms-of-use/TermsOfUseAlert';
import CommandBarProvider from 'src/features/command-bar/CommandBarProvider/CommandBarProvider';
import TopMenu from 'src/features/topmenu';
import Impersonate from 'src/features/support/Impersonate';

import styles from './AppLayout.module.less';

function AppLayout() {
  return (
    <CommandBarProvider>
      <TopMenu />
      <Layout>
        <Layout.Content className={styles.content}>
          <TermsOfUseAlert />
          <Outlet />
        </Layout.Content>
      </Layout>

      <Impersonate />
    </CommandBarProvider>
  );
}

export default AppLayout;
