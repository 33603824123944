import t from 'zod';

import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import { ScheduledRecurrenceBaseDecoder } from 'src/libs/finbits/Management/ScheduledRecurrences/types';
import { CategoryDecoder } from 'src/libs/finbits/Categories/categories/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

export const ScheduledEntryDecoder = t.object({
  id: t.string(),
  companyId: t.string(),
  accountId: t.string(),
  amount: t.number(),
  description: t.string(),
  category: CategoryDecoder.nullish(),
  categoryId: t.string().nullable(),
  contact: ContactDecoder.nullish(),
  contactId: t.string().nullable(),
  comments: t.string().nullable(),
  relevantDate: t.string().nullable(),
  type: t.nativeEnum(BalanceType),
  date: t.string(),
  attachments: AttachmentDecoder.array().optional(),
  account: AccountDecoder.optional(),
  scheduledRecurrence: ScheduledRecurrenceBaseDecoder.nullish(),
  labels: LabelDecoder.array().optional(),
  notaFiscalId: t.string().nullish(),
});

export const ScheduledEntriesDecoder = t.array(ScheduledEntryDecoder);

export type ScheduledEntry = t.TypeOf<typeof ScheduledEntryDecoder>;
export type ScheduledEntries = t.TypeOf<typeof ScheduledEntriesDecoder>;

export type ScheduledEntriesGetParams = {
  organizationId?: string;
  companyId?: string;
  nonConciliated?: boolean;
  overdue?: boolean;
  enabled?: boolean;
  type?: BalanceType;
  order?: 'asc' | 'desc';
};
