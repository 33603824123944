import { Button, Tooltip } from '@mui/material';
import { UploadCloud01Icon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import ImportClassificationModal from '../ImportClassificationModal';

export default function ImportClassificationsButton() {
  const openPortal = useOpenPortal();

  const { isEnabled } = useExternalFeatureFlag(Feature.IMPORT_CLASSIFICATIONS);

  function handleClassificationsModal() {
    openPortal(ImportClassificationModal);
  }

  return (
    isEnabled && (
      <WithAllowedPermission
        permissions={{
          resource: 'classifications',
          action: 'create',
        }}
      >
        <Tooltip
          placement="bottom"
          title="Importar planilha de etiquetas"
          arrow
        >
          <Button
            aria-label="Importar planilha de etiquetas"
            variant="outlined"
            onClick={handleClassificationsModal}
          >
            <UploadCloud01Icon />
          </Button>
        </Tooltip>
      </WithAllowedPermission>
    )
  );
}
