import { Button } from '@mui/material';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateBillModal from 'src/features/bills-to-pay/BillModal/CreateBillModal';

export default function CreateBillButton() {
  const openPortal = useOpenPortal();

  function handleOpenCreateBillDrawer() {
    openPortal(CreateBillModal);
  }

  return (
    <WithAllowedPermission
      permissions={[
        {
          resource: 'bills',
          action: 'create',
        },
      ]}
      key="createScheduled"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenCreateBillDrawer}
      >
        Criar lançamento a pagar
      </Button>
    </WithAllowedPermission>
  );
}
