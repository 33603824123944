import { useMutation, useQuery, useQueryClient } from 'react-query';

import type {
  ApiError,
  ApiErrorForm,
  ApiResponse,
  ErrorResponse,
} from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  handleError,
  handleSuccess,
} from 'src/libs/finbits/client';
import {
  invalidateEntriesQueries,
  invalidateFinancialStatementEntriesQueries,
} from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateConciliationSuggestionsQueries } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import { format } from 'src/libs/finbits/Date';
import type { IgnoredRecord } from 'src/libs/finbits/Management/IgnoredRecords/types';
import { invalidateScheduledEntriesQueries } from 'src/libs/finbits/Management/ScheduledEntries';
import { invalidateAttachmentsQueries } from 'src/libs/finbits/Management/Attachments';
import { invalidateNotasFiscaisQueries } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { invalidateOverviewQueries } from 'src/libs/finbits/Overview';

import type {
  FinancialEntriesEditable,
  FinancialEntry,
  FinancialEntryConciliateParams,
  FinancialEntryGetParams,
  FinancialEntryPatchParams,
  FinancialEntryPostParams,
  FinancialEntryUnconciliateParams,
} from './types';
import { FinancialEntryDecoder } from './types';

export async function getFinancialEntry(
  organizationId: string,
  companyId: string,
  financialEntryId: string
): Promise<FinancialEntry | ErrorResponse> {
  const response = await authenticatedAPI
    .get(
      `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}`
    )
    .then(handleSuccess)
    .catch(handleError);

  if (response.type === 'success') {
    return { ...response.data } as FinancialEntry;
  }

  return { ...response.data, isError: true } as ErrorResponse;
}

export async function updateFinancialEntry(
  organizationId: string,
  companyId: string,
  {
    id,
    description,
    categoryId,
    contactId,
    relevantDate,
    comments,
  }: FinancialEntriesEditable
): Promise<'ok' | ErrorResponse> {
  const response = await authenticatedAPI
    .patch(
      `/organizations/${organizationId}/companies/${companyId}/financial_entries/${id}`,
      { description, categoryId, contactId, relevantDate, comments }
    )
    .then(handleSuccess)
    .catch(handleError);

  return formatUpdate(response);
}

function formatUpdate(response: ApiResponse): 'ok' | ErrorResponse {
  if (response.type === 'success') {
    return 'ok';
  }

  return { ...response.data, isError: true } as ErrorResponse;
}

async function getFinancialEntryForQuery({
  organizationId,
  companyId,
  financialEntryId,
}: FinancialEntryGetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}`
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

export function financialEntryCacheKey(financialEntryId: string) {
  return ['financial_entry', financialEntryId];
}

export function useFinancialEntry(params: FinancialEntryGetParams) {
  const { data, ...rest } = useQuery<FinancialEntry, ApiError>({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: financialEntryCacheKey(params.financialEntryId),
    queryFn: () => getFinancialEntryForQuery(params),
  });

  return { financialEntry: data, ...rest };
}

async function patchFinancialEntry({
  organizationId,
  companyId,
  financialEntryId,
  relevantDate,
  labelsIds,
  ...params
}: FinancialEntryPatchParams) {
  const formattedRelevantDate = relevantDate
    ? format(relevantDate, 'yyyy-MM-dd')
    : null;

  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}`,
    {
      relevantDate: formattedRelevantDate,
      labelsIds,
      ...params,
    }
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function postFinancialEntry({
  organizationId,
  companyId,
  ...params
}: FinancialEntryPostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries`,
    params
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function postFinancialEntryConciliation({
  organizationId,
  companyId,
  financialEntryId,
  ...bodyParams
}: FinancialEntryConciliateParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}/conciliation`,
    bodyParams
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function deleteFinancialEntryConciliation({
  organizationId,
  companyId,
  financialEntryId,
}: FinancialEntryUnconciliateParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}/conciliation`
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function deleteFinancialEntry({
  organizationId,
  companyId,
  financialEntryId,
}: FinancialEntryGetParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/financial_entries/${financialEntryId}`
  );

  return response.data;
}

export function useUpdateFinancialEntry() {
  const queryClient = useQueryClient();

  const { mutate: updateFinancialEntry, ...rest } = useMutation<
    FinancialEntry,
    ApiError<ApiErrorForm>,
    FinancialEntryPatchParams
  >(patchFinancialEntry, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateNotasFiscaisQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { updateFinancialEntry, ...rest };
}

export function useCreateFinancialEntry() {
  const queryClient = useQueryClient();

  const { mutate: createFinancialEntry, ...rest } = useMutation<
    FinancialEntry,
    ApiError<ApiErrorForm>,
    FinancialEntryPostParams
  >(postFinancialEntry, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
      invalidateNotasFiscaisQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { createFinancialEntry, ...rest };
}

export function useConciliateFinancialEntry() {
  const queryClient = useQueryClient();
  const { mutate: conciliateFinancialEntry, ...rest } = useMutation<
    FinancialEntry,
    ApiError,
    FinancialEntryConciliateParams
  >(postFinancialEntryConciliation, {
    onSuccess: (financialEntry) => {
      queryClient.invalidateQueries(financialEntryCacheKey(financialEntry.id));

      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { conciliateFinancialEntry, ...rest };
}

export function useUnconciliateFinancialEntry() {
  const queryClient = useQueryClient();
  const { mutate: unconciliateFinancialEntry, ...rest } = useMutation<
    FinancialEntry,
    ApiError,
    FinancialEntryUnconciliateParams
  >(deleteFinancialEntryConciliation, {
    onSuccess: (financialEntry) => {
      queryClient.setQueryData(
        financialEntryCacheKey(financialEntry.id),
        financialEntry
      );

      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { unconciliateFinancialEntry, ...rest };
}

export function useDeleteFinancialEntry() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    IgnoredRecord,
    ApiError,
    FinancialEntryGetParams
  >(deleteFinancialEntry, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
      invalidateNotasFiscaisQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });
  return { deleteFinancialEntry: mutate, ...rest };
}
