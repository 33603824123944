import type { TransitionProps } from '@mui/material/transitions';
import { Button, DialogContent, Divider, Slide, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useAddCategory } from 'src/libs/finbits/Categories/categories';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type {
  Category,
  CategoryParams,
} from 'src/libs/finbits/Categories/categories/types';

import CategoryForm from 'src/features/categories/CategoryForm';

type Props = {
  defaultValues?: Partial<Category>;
  disabledFields?: Array<keyof CategoryParams>;
  onCreateSuccess?: (category: Category) => void;
} & PortalProps;

export default function CreateCategoryModal({
  defaultValues,
  disabledFields,
  onClose,
  onCreateSuccess,
  open = true,
  onExit,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { addCategory, isLoading, error } = useAddCategory();

  function handleCreateCategory(payload: CategoryParams) {
    const newCategory = {
      name: payload.name,
      optionalAttachments: payload.optionalAttachments,
      includeInCashFlow: !payload.notIncludeInCashFlow,
      type: payload.type,
    };

    addCategory(
      {
        companyId,
        organizationId,
        payload: newCategory,
      },
      {
        onSuccess: (response) => {
          snackbar({ variant: 'success', message: 'Categoria adicionada!' });

          onCreateSuccess && onCreateSuccess(response.data);

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao criar a categoria.',
          });
        },
      }
    );
  }

  return (
    <Dialog
      title="Criar Categoria"
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      sx={{ zIndex: 1001 }}
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Criar Categoria
          </Stack>
        }
        onClose={onClose}
      />
      <Divider />
      <DialogContent>
        <CategoryForm
          disabledFields={disabledFields}
          defaultValues={defaultValues}
          responseError={error?.response?.data?.errors}
          onSubmit={handleCreateCategory}
        />
      </DialogContent>
      <Divider />
      <Dialog.Actions size="small">
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="category-form"
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          Salvar
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
