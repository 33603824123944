import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import type {
  BillFormParams,
  BillParams,
  BillPayable,
} from 'src/libs/finbits/Bills/types';
import { useCreateBill, useCreateRecurringBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { isStructuredFormError, setErrorsInForm } from 'src/libs/finbits/Form';
import { buildRecurrencePayload } from 'src/libs/finbits/Management/ScheduledRecurrences';
import { useInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';

import EntryModal from 'src/features/entries/Modals/EntryModal';

import BillContent from '../BillContent';
import useBillForm from '../useBillForm';
import ModalHeader from '../ModalHeader';
import { parseFormToSubmit } from '../formValues';

type Props = {
  inboxItemId?: string;
  inboxAttachmentsStatus?: InboxItemAttachmentsStatus;
  onSuccess?: () => void;
  defaultBill?: BillPayable;
} & PortalProps;

export default function CreateBillModal({
  inboxItemId,
  inboxAttachmentsStatus,
  onClose,
  onSuccess,
  open = true,
  onExit,
  defaultBill,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { bill, isFetching } = useInboxItemMerge({
    companyId,
    organizationId,
    inboxItemId,
  });

  const form = useBillForm({
    bill: bill ?? defaultBill,
    isBillLoading: isFetching,
  });

  const { createBill, isLoading: isSaving } = useCreateBill();
  const { createRecurringBill, isLoading: isSavingRecurring } =
    useCreateRecurringBill();

  function handleCreateBill(params: BillFormParams) {
    const handleApiResponse = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: params.isRecurrenceEnabled
            ? 'Recorrência criada com sucesso'
            : 'O novo lançamento a pagar foi adicionado com sucesso!',
        });

        onSuccess?.();

        onClose();
      },
      onError: ({ response }: ApiError<ApiErrorForm>) => {
        snackbar({
          variant: 'error',
          message: params.isRecurrenceEnabled
            ? 'Ocorreu um erro ao criar a recorrência'
            : 'Ocorreu um erro ao criar o lançamento a pagar!',
        });

        if (response?.data.errors && isStructuredFormError(response?.data)) {
          setErrorsInForm(response.data.errors, form.setError);
        }
      },
    };

    if (params.isRecurrenceEnabled) {
      const recurrencePayload = buildRecurrencePayload<
        BillFormParams,
        BillParams
      >({ formParams: params, formParser: parseFormToSubmit, inboxItemId });

      createRecurringBill(
        {
          ...recurrencePayload,
          companyId,
          organizationId,
        },
        handleApiResponse
      );

      return;
    }

    createBill(
      {
        ...parseFormToSubmit(params),
        inboxItemId,
        companyId,
        organizationId,
      },
      handleApiResponse
    );
  }

  return (
    <EntryModal<BillFormParams>
      onClose={onClose}
      open={open}
      onExit={onExit}
      onSubmit={handleCreateBill}
      isSaving={isSaving || isSavingRecurring}
      form={form}
      Header={(props) => <ModalHeader {...props} onSuccess={onSuccess} />}
      isLoading={isFetching}
      inboxItemId={inboxItemId}
      inboxAttachmentsStatus={inboxAttachmentsStatus}
      editableFields={bill?.editableFields}
      suggestedFields={bill?.suggestedFields}
      contact={bill?.contact}
    >
      <BillContent bill={bill} />
    </EntryModal>
  );
}
