import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useGetBill, useUpdateBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError, setErrorsInForm } from 'src/libs/finbits/Form';

import { Tab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import EntryModal from 'src/features/entries/Modals/EntryModal';

import BillContent from '../BillContent';
import useBillForm from '../useBillForm';
import ModalHeader from '../ModalHeader';
import { parseFormToSubmit } from '../formValues';

import DeleteButton from './DeleteButton';

type Props = {
  billId: string;
  onSuccess?: () => void;
  inboxItemId?: string;
} & PortalProps;

export default function EditBillModal({
  onClose,
  open = true,
  onExit,
  onSuccess,
  billId,
  inboxItemId,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { bill, isLoading } = useGetBill({
    companyId,
    organizationId,
    billId,
    includingDeleted: true,
  });

  const form = useBillForm({
    bill,
    isBillLoading: isLoading,
  });

  const { updateBill, isLoading: isSaving } = useUpdateBill();

  const disabledTabs = { [Tab.RECURRENCE]: true };

  function handleUpdateBill(params: BillFormParams) {
    if (!billId) return;

    updateBill(
      {
        ...parseFormToSubmit(params),
        billId,
        inboxItemId,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'O lançamento a pagar foi atualizado com sucesso!',
          });

          onClose();
          onSuccess?.();
        },
        onError: ({ response }: ApiError<ApiErrorForm>) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao atualizar o lançamento a pagar!',
          });

          if (response?.data.errors && isStructuredFormError(response?.data)) {
            setErrorsInForm(response.data.errors, form.setError);
          }
        },
      }
    );
  }

  return (
    <EntryModal<BillFormParams>
      onClose={onClose}
      open={open}
      onExit={onExit}
      onSubmit={handleUpdateBill}
      isSaving={isSaving}
      form={form}
      Header={(props) => (
        <ModalHeader {...props} bill={bill} onSuccess={onClose} />
      )}
      isLoading={isLoading}
      inboxItemId={inboxItemId}
      disabledTabs={disabledTabs}
      editableFields={bill?.editableFields}
      suggestedFields={bill?.suggestedFields}
      contact={bill?.contact}
      isEditMode
      Actions={<DeleteButton bill={bill} onSuccess={onClose} />}
    >
      <BillContent bill={bill} />
    </EntryModal>
  );
}
