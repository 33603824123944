import { useMutation, useQueryClient } from 'react-query';

import type { ApiError, ApiErrorForm } from '../../client';

import { createSicoobConsentment } from './api';
import type { CreateSicoobConsentmentParams } from './types';

export function useCreateSicoobConsentment() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    CreateSicoobConsentmentParams,
    ApiError<ApiErrorForm>,
    CreateSicoobConsentmentParams
  >(createSicoobConsentment, {
    onSuccess: ({ accountId }) => {
      queryClient.invalidateQueries({
        queryKey: ['openBankingConnections', { accountId }],
      });
    },
  });
  return { createSicoobConsentment: mutate, ...rest };
}
