import { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import AccountPlan from '../AccountPlan';
import OtherCategories from '../OtherCategories';

import styles from './CategoriesTabs.module.scss';
import { CategoriesTabs } from './enum';

function a11yProps(index: number) {
  return {
    id: `categories-tabs-${index}`,
    'aria-controls': `categories-tabpanel-${index}`,
  };
}

export default function CategoriesPageTabs() {
  const [tabValue, setTabValue] = useState(CategoriesTabs.ACCOUNT_PLAN);

  function handleTabChange(_event: React.SyntheticEvent, tab: CategoriesTabs) {
    setTabValue(tab);
  }

  return (
    <TabContext value={tabValue}>
      <Box>
        <Tabs
          className={styles.tabs}
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="categorias"
        >
          <Tab
            label="Plano de Contas"
            {...a11yProps(0)}
            value={CategoriesTabs.ACCOUNT_PLAN}
          />
          <Tab
            label="Outras categorias"
            {...a11yProps(1)}
            value={CategoriesTabs.OTHER_CATEGORIES}
          />
        </Tabs>
      </Box>

      <TabPanel value={CategoriesTabs.ACCOUNT_PLAN}>
        <AccountPlan />
      </TabPanel>
      <TabPanel value={CategoriesTabs.OTHER_CATEGORIES}>
        <OtherCategories />
      </TabPanel>
    </TabContext>
  );
}
