import { Chip } from 'src/design-system/components';
import { Stack } from '@mui/material';
import { ArrowNarrowDownIcon } from 'src/mui/_icons';

import { Dialog } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import HeaderActions from './HeaderActions/';

type Props = {
  handleClose: () => void;
  bill?: BillPayable;
  onSuccess?: () => void;
};

export default function ModalHeader({ bill, handleClose, onSuccess }: Props) {
  return (
    <Dialog.Header
      size="small"
      flex={1}
      title={
        <Stack
          direction="row"
          gap={2}
          flex={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            Pagamento Programado
            <Chip
              label="Saída"
              color="error"
              size="small"
              icon={<ArrowNarrowDownIcon strokeWidth="2.5" />}
            />
          </Stack>
          <div>
            {bill && <HeaderActions bill={bill} onSuccess={onSuccess} />}
          </div>
        </Stack>
      }
      onClose={handleClose}
    />
  );
}
