import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateBillsQueries } from 'src/libs/finbits/Bills/Bills';
import { invalidateOverviewQueries } from 'src/libs/finbits/Overview';

import type { PatchParams } from './types';

async function patchEntriesInBatch({
  organizationId,
  companyId,
  billsIds = [],
  financialEntriesIds = [],
  receivablesIds = [],
  ...params
}: PatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/entries`,
    { ...params, billsIds, financialEntriesIds, receivablesIds }
  );

  return response;
}

export function useUpdateEntriesInBatch() {
  const queryClient = useQueryClient();

  const { mutate: updateEntries, ...rest } = useMutation<
    unknown,
    ApiError,
    PatchParams
  >(patchEntriesInBatch, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
      invalidateBillsQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { updateEntries, ...rest };
}
