import t from 'zod';

export const OverviewTotalsDecoder = t.object({
  debitAmount: t.number(),
  debitAmountNotIncluded: t.number(),
  creditAmount: t.number(),
  creditAmountNotIncluded: t.number(),
  currentBalance: t.number(),
  periodBalance: t.number(),
  resultAmount: t.number(),
});

export const OverviewCashFlowDecoder = t.object({
  balanceAmount: t.number().nullable(),
  balancePercentageVariation: t.number().nullable(),
  balanceVariationAmount: t.number().nullable(),
  creditAmountTotal: t.number().nullable(),
  creditAmount: t.number().nullable(),
  creditAmountPercentageVariation: t.number().nullable(),
  creditAmountVariation: t.number().nullable(),
  debitAmount: t.number().nullable(),
  debitAmountTotal: t.number().nullable(),
  debitAmountPercentageVariation: t.number().nullable(),
  debitAmountVariation: t.number().nullable(),
  totalAmount: t.number().nullable(),
  date: t.string(),
  balanceAmountProjected: t.number().nullable(),
  balanceAmountProjectedVariation: t.number().nullable(),
  balanceAmountProjectedPercentageVariation: t.number().nullable(),
  creditAmountProjected: t.number().nullable(),
  creditAmountProjectedVariation: t.number().nullable(),
  creditAmountProjectedPercentageVariation: t.number().nullable(),
  debitAmountProjected: t.number().nullable(),
  debitAmountProjectedPercentageVariation: t.number().nullable(),
  debitAmountProjectedVariation: t.number().nullable(),
});
