import type { ReactNode } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import { Button, DialogContent, Slide } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Loader } from 'src/ui';

import type { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { ContactOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

import TabPanelsProvider from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import EntrySuggestionsProvider from 'src/features/entries/Suggestions/EntrySuggestionsProvider';
import type { TabValues } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import EditableFieldsProvider from 'src/features/entries/Modals/EntryModal/EditableFieldsProvider';
import type { DirtyFields } from 'src/features/entries/Suggestions/EntrySuggestionsProvider/types';
import AutofillSuggestionsProvider from 'src/features/entries/Modals/EntryModal/autofill-suggestions/AutofillSuggestionsProvider';
import type { EditableFields } from 'src/features/EntryForm/types';

import styles from './EntryModal.module.scss';

type Props<FormParams extends FieldValues> = {
  isLoading?: boolean;
  isSaving?: boolean;
  onSubmit: (params: FormParams) => void;
  disabledTabs?: TabValues;
  inboxItemId?: string;
  isEditMode?: boolean;
  Actions?: ReactNode;
  inboxAttachmentsStatus?: InboxItemAttachmentsStatus;
  form: UseFormReturn<FormParams>;
  children?: ReactNode;
  Header: React.ComponentType<{
    handleClose: () => void;
  }>;
  editableFields?: EditableFields[] | null;
  suggestedFields?: string[];
  contact?: Contact | ContactOptional | null;
} & PortalProps;

export default function EntryModal<FormParams extends FieldValues>({
  onClose,
  open = true,
  onExit,
  isLoading = true,
  isSaving = false,
  onSubmit,
  disabledTabs,
  inboxItemId,
  isEditMode,
  Actions,
  inboxAttachmentsStatus,
  form,
  children,
  editableFields,
  suggestedFields,
  contact,
  Header,
}: Props<FormParams>) {
  const openLeaveDialog = useOpenConfirmLeaveFormDialog();

  const {
    formState: { isDirty, dirtyFields },
    watch,
  } = form;

  function handleClose() {
    if (isDirty) {
      return openLeaveDialog({
        onConfirm: onClose,
      });
    }

    onClose();
  }

  async function handleClick() {
    form.handleSubmit(onSubmit)();
  }

  const { attachments, contactId, isRecurrenceEnabled, paymentDetails } =
    watch();

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={handleClose}
      fullWidth
      maxWidth="uxl"
      // TODO: Habilitar essa prop novamente quando tivermos a drawer de contato usando componentes do MUI
      disableEnforceFocus
    >
      <Header handleClose={handleClose} />
      <DialogContent className={styles.dialogContent} dividers>
        {isLoading ? (
          <Loader />
        ) : (
          <FormProvider {...form}>
            <AutofillSuggestionsProvider
              inboxItemId={inboxItemId}
              inboxAttachmentsStatus={inboxAttachmentsStatus}
            >
              <EditableFieldsProvider
                isEditMode={isEditMode}
                editableFields={editableFields}
              >
                <TabPanelsProvider disabledTabs={disabledTabs}>
                  <EntrySuggestionsProvider
                    attachments={attachments}
                    contactId={contactId}
                    paymentMethod={paymentDetails?.paymentMethod}
                    inboxItemId={inboxItemId}
                    contact={contact}
                    suggestedFields={suggestedFields}
                    dirtyFields={dirtyFields as DirtyFields}
                  >
                    {children}
                  </EntrySuggestionsProvider>
                </TabPanelsProvider>
              </EditableFieldsProvider>
            </AutofillSuggestionsProvider>
          </FormProvider>
        )}
      </DialogContent>

      <Dialog.Actions className={styles.dialogActions}>
        {Actions}
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isSaving}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
          {isRecurrenceEnabled ? 'Salvar e criar recorrência' : 'Salvar'}
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
