import type {
  AllSeriesType,
  BarItemIdentifier,
  LineItemIdentifier,
} from '@mui/x-charts-pro';
import {
  BarPlot,
  ChartsAxisHighlight,
  ChartsClipPath,
  ChartsGrid,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainerPro,
  ZoomSetup,
} from '@mui/x-charts-pro';
import {
  Box,
  Fade,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import classNames from 'classnames';

import { useChartTableSyncContext } from 'src/pages/Organizations/Companies/Overview/ChartTableSyncProvider';

import { toFloat } from 'src/libs/finbits/Money';
import { format, parseISO } from 'src/libs/finbits/Date';
import {
  CashFlowDataKeys,
  CashFlowGroupBy,
} from 'src/libs/finbits/Overview/enum';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import EmptyState from './EmptyState';
import Legends from './Legends/Legends';
import LoadingState from './LoadingState';
import ZoomTooltip from './ZoomTooltip';
import styles from './OverviewChart.module.scss';
import CustomItemTooltip from './Tooltips/CustomItemTooltip';
import { useChartConfig } from './useChartConfig/useChartConfig';

const CHART_PATH_ID = 'cashFlowChart';
const Y_AXIS_ID = 'yAxis';
const X_AXIS_ID = 'bar';

const dateFormatByX = {
  [CashFlowGroupBy.DAY]: 'dd/MM',
  [CashFlowGroupBy.MONTH]: 'MMM/yy',
  [CashFlowGroupBy.YEAR]: 'yyyy',
};

function formatXAxis(xAxis: CashFlowGroupBy) {
  return function formatDate(v: string) {
    return format(parseISO(v), dateFormatByX[xAxis]);
  };
}

function formatYAxis(v: number | null) {
  return toFloat(v ?? 0).toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
}

function buildSeries(isEmpty: boolean, isEnabled: boolean): AllSeriesType[] {
  if (isEmpty) {
    return [];
  }

  if (isEnabled) {
    return [
      {
        type: 'line',
        curve: 'linear',
        dataKey: CashFlowDataKeys.BALANCE_AMOUNT,
        label: 'Saldo',
        color: 'black',
        showMark: true,
        id: CashFlowDataKeys.BALANCE_AMOUNT,
      },
      {
        type: 'line',
        curve: 'linear',
        dataKey: CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED,
        label: 'Saldo projetado',
        color: '#A3A3A3',
        showMark: true,
        id: CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED,
      },
      {
        type: 'bar',
        dataKey: CashFlowDataKeys.CREDIT_AMOUNT,
        label: 'Entradas',
        stack: 'amountBar',
        color: '#108036',
        id: CashFlowDataKeys.CREDIT_AMOUNT,
      },
      {
        type: 'bar',
        dataKey: CashFlowDataKeys.DEBIT_AMOUNT,
        label: 'Saídas',
        stack: 'amountBar',
        color: '#292929',
        id: CashFlowDataKeys.DEBIT_AMOUNT,
      },
      {
        type: 'bar',
        dataKey: CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED,
        label: 'Entradas programadas',
        stack: 'amountBar',
        color: '#C3DFCD',
        id: CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED,
      },
      {
        type: 'bar',
        dataKey: CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED,
        label: 'Saídas programadas',
        stack: 'amountBar',
        color: '#AEB7AE',
        id: CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED,
      },
    ];
  }

  return [
    {
      type: 'line',
      curve: 'linear',
      dataKey: CashFlowDataKeys.BALANCE_AMOUNT,
      label: 'Saldo',
      color: 'black',
      showMark: true,
      id: CashFlowDataKeys.BALANCE_AMOUNT,
    },
    {
      type: 'bar',
      dataKey: CashFlowDataKeys.CREDIT_AMOUNT,
      label: 'Entradas',
      stack: 'amountBar',
      color: '#108036',
      id: CashFlowDataKeys.CREDIT_AMOUNT,
    },
    {
      type: 'bar',
      dataKey: CashFlowDataKeys.DEBIT_AMOUNT,
      label: 'Saídas',
      stack: 'amountBar',
      color: '#292929',
      id: CashFlowDataKeys.DEBIT_AMOUNT,
    },
  ];
}

const fakeEmptyData = Array.from({ length: 12 }, (_item, i) =>
  format(new Date(2025, i), 'yyyy-MM-dd')
);
const fakeEmptyAmount = [10000, 1000, 0, -1000, -10000];

const isMac = navigator.userAgent.includes('Mac');

type Props = {
  data: OverviewCashFlow[];
  xAxisGroupedBy: CashFlowGroupBy;
  onChangeXAxis: (xAxis: CashFlowGroupBy) => void;
  isLoading?: boolean;
};

export default function OverviewChart({
  data,
  xAxisGroupedBy,
  onChangeXAxis,
  isLoading = false,
}: Props) {
  const {
    zoom,
    zoomOptions,
    chartDate,
    loading,
    isEmpty,
    emptyOrLoading,
    reset,
    onZoomChange,
    axisClasses,
    blockZoom,
    chartRef,
  } = useChartConfig({ data, isLoading, axisId: X_AXIS_ID });

  const { syncTableWithChart } = useChartTableSyncContext();

  function handleSelectXAxis(
    _event: React.MouseEvent<HTMLElement>,
    newAxis: CashFlowGroupBy
  ) {
    if (newAxis !== null) {
      reset();
      onChangeXAxis(newAxis);
    }
  }

  function handleClickItem(
    event: React.MouseEvent<SVGElement, MouseEvent>,
    item: LineItemIdentifier | BarItemIdentifier
  ) {
    if (typeof item?.dataIndex !== 'undefined') {
      const date = chartDate[item.dataIndex].date;

      syncTableWithChart?.(event, date);
    }
  }

  const chartClassName = classNames(styles.chart, {
    [styles.blockZoom]: blockZoom,
    [styles.isMac]: isMac,
  });

  const { isEnabled } = useExternalFeatureFlag(Feature.OVERVIEW_PROJECTED);

  return (
    <Box className={styles.container} ref={chartRef}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={5}>
          <ToggleButtonGroup
            color="secondary"
            value={emptyOrLoading ? null : xAxisGroupedBy}
            exclusive
            onChange={handleSelectXAxis}
            aria-label="Platform"
            size="small"
            disabled={emptyOrLoading}
          >
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.YEAR}
            >
              Anos
            </ToggleButton>
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.MONTH}
            >
              Meses
            </ToggleButton>
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.DAY}
            >
              Dias
            </ToggleButton>
          </ToggleButtonGroup>
          <ZoomTooltip isEmpty={emptyOrLoading} />
        </Stack>
        <Box>
          <Fade in={!emptyOrLoading}>
            <Legends />
          </Fade>
        </Box>
      </Stack>

      <ResponsiveChartContainerPro
        data-testid="overview-chart"
        className={chartClassName}
        disableAxisListener={emptyOrLoading}
        margin={{ top: 40, bottom: 30, left: 50, right: 10 }}
        onZoomChange={onZoomChange}
        zoom={zoom}
        dataset={chartDate}
        series={buildSeries(emptyOrLoading, isEnabled)}
        xAxis={
          [
            {
              id: X_AXIS_ID,
              dataKey: 'date',
              scaleType: 'band',
              valueFormatter: formatXAxis(xAxisGroupedBy),
              zoom: zoomOptions,
              data: emptyOrLoading ? fakeEmptyData : undefined,
            },
          ] as any
        }
        yAxis={[
          {
            id: Y_AXIS_ID,
            scaleType: emptyOrLoading ? 'point' : undefined,
            valueFormatter: formatYAxis,
            data: emptyOrLoading ? fakeEmptyAmount : undefined,
          },
        ]}
      >
        {!emptyOrLoading && <ChartsGrid horizontal />}

        <ChartsClipPath id={CHART_PATH_ID} />
        <g clipPath={`url(#${CHART_PATH_ID})`}>
          {loading && <LoadingState />}
          {!loading && isEmpty && <EmptyState />}
          <BarPlot onItemClick={handleClickItem} skipAnimation />
          <LinePlot skipAnimation />
          <MarkPlot onItemClick={handleClickItem} skipAnimation />
        </g>

        <ChartsYAxis position="left" axisId={Y_AXIS_ID} classes={axisClasses} />
        <ChartsXAxis
          position="bottom"
          axisId={X_AXIS_ID}
          classes={axisClasses}
        />

        <LineHighlightPlot />
        <ChartsAxisHighlight x="band" y="line" />
        <CustomItemTooltip data={chartDate} groupedBy={xAxisGroupedBy} />
        <ZoomSetup />
        <ChartsReferenceLine y={0} classes={{ line: styles.referenceLine }} />
      </ResponsiveChartContainerPro>
    </Box>
  );
}
