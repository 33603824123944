import type { QueryClient } from 'react-query';
import { useQuery } from 'react-query';
import t from 'zod';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import type {
  OverviewCashFlow,
  OverviewCashFlowParams,
  OverviewTotals,
  OverviewTotalsParams,
} from './types';
import { OverviewCashFlowDecoder, OverviewTotalsDecoder } from './validations';

async function overviewTotals({
  organizationId,
  companyId,
  ...params
}: OverviewTotalsParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/overview/totals`,
    { params }
  );

  return decodeResponse<OverviewTotals>(response, OverviewTotalsDecoder);
}

export function useOverviewTotals(params: OverviewTotalsParams) {
  const { data, ...rest } = useQuery<OverviewTotals, ApiError>({
    enabled:
      !!params.accountsIds?.length &&
      !!params.companyId &&
      !!params.organizationId &&
      !!params.startDate &&
      !!params.endDate,
    queryKey: overviewKeys.totals(params),
    queryFn: () => overviewTotals(params),
  });

  return { totals: data, ...rest };
}

async function overviewCashFlow({
  organizationId,
  companyId,
  ...params
}: OverviewCashFlowParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/overview/cashflow`,
    { params }
  );

  return decodeResponse<OverviewCashFlow[]>(
    response,
    t.array(OverviewCashFlowDecoder)
  );
}

export function useOverviewCashFlow(params: OverviewCashFlowParams) {
  const { data, ...rest } = useQuery<OverviewCashFlow[], ApiError>({
    enabled:
      !!params.accountsIds.length &&
      !!params.companyId &&
      !!params.organizationId &&
      !!params.startDate &&
      !!params.endDate &&
      !!params.groupBy,
    queryKey: overviewKeys.cashFlow(params),
    queryFn: () => overviewCashFlow(params),
  });

  return { cashFlow: withEmptyArrayDefault(data), ...rest };
}

const overviewKeys = {
  all: ['overview'] as const,
  totals: (params: OverviewTotalsParams) =>
    [...overviewKeys.all, 'totals', params] as const,
  cashFlow: (params: OverviewCashFlowParams) =>
    [...overviewKeys.all, 'cashFlow', params] as const,
};

function invalidateOverviewQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(overviewKeys.all);
}

export const invalidateOverviewQueries = throttle(
  invalidateOverviewQueriesCall,
  FIVE_SECONDS_IN_MS
);
