import { UploadOutlined } from '@ant-design/icons';
import type { RcFile } from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import { Modal, Title } from 'src/ui';

import { useImportCategories } from 'src/libs/finbits/Categories/categories';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import UploadContent from './UploadContent';

type Props = { onClose: () => void };

export default function ImportCategoriesModal({ onClose }: Props) {
  const { importCategories, isLoading: isUploading } = useImportCategories();
  const { companyId, organizationId } = useCompanyParams();

  function importRequest(file: RcFile) {
    if (!organizationId || !companyId) return;

    const requestParams = { organizationId, companyId, file };

    importCategories(requestParams, {
      onSuccess: () => {
        onClose();
        snackbar({
          variant: 'success',
          message: 'Planilha importada com sucesso.',
        });
      },
      onError: ({ response }) => {
        const errors = response?.data.errors;

        snackbar({
          variant: 'error',
          title: 'Erro na importação',
          message: JSON.stringify(errors),
          alignItems: 'start',
        });
      },
    });
  }

  return (
    <Modal
      destroyOnClose
      visible
      onCancel={onClose}
      title={
        <Title level={4} icon={<UploadOutlined />}>
          Importar planilha de categorias
        </Title>
      }
    >
      <UploadContent
        onImportRequest={importRequest}
        isUploading={isUploading}
      />
    </Modal>
  );
}
