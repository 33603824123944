import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';

export default function AccountPlan() {
  return (
    <Box>
      <Typography>Plano de Contas</Typography>
    </Box>
  );
}
