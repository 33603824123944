import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { FormField, Input } from 'src/mui/_scss';

import { SicoobAutoPaymentClientIDResolver } from 'src/libs/finbits/Bank/Sicoob';

import AutoPaymentCard from 'src/features/open-banking/AutoPayment/AutoPaymentModal/AutoPaymentCard';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

type ClientIdCallback = {
  setClientId: (clientId: string) => void;
};

type Props = StepperContentProps & ClientIdCallback;

export default function GetClientIdStep({
  onNext,
  onPrev,
  setClientId,
}: Props) {
  const form = useForm({
    resolver: zodResolver(SicoobAutoPaymentClientIDResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientId: '',
    },
  });

  async function handleSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (!isValid) return;

    const { clientId } = form.getValues();
    setClientId(clientId);
    onNext?.(event);
  }

  return (
    <>
      <Box>
        <FormProvider {...form}>
          <AutoPaymentCard>
            <Box width="100%">
              <FormField name="clientId" label="Client ID">
                {(field) => (
                  <Input {...field} placeholder="Informe o Client ID" />
                )}
              </FormField>
            </Box>
          </AutoPaymentCard>
        </FormProvider>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="48px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Continuar
        </Button>
      </Box>
    </>
  );
}
