import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useUpdateBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';
import type { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError, setErrorsInForm } from 'src/libs/finbits/Form';

import { Tab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import EntryModal from 'src/features/entries/Modals/EntryModal';

import BillContent from '../BillContent';
import useBillForm from '../useBillForm';
import ModalHeader from '../ModalHeader';
import { parseFormToSubmit } from '../formValues';

type Props = {
  billId?: string;
  onSuccess?: () => void;
  inboxItemId?: string;
  inboxAttachmentsStatus?: InboxItemAttachmentsStatus;
} & PortalProps;

export default function MergeBillModal({
  onClose,
  open = true,
  onExit,
  onSuccess,
  billId,
  inboxItemId,
  inboxAttachmentsStatus,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { bill, isLoading } = useInboxItemMerge({
    companyId,
    organizationId,
    inboxItemId,
    billId,
  });

  const form = useBillForm({
    bill,
    isBillLoading: isLoading,
  });

  const { updateBill, isLoading: isSaving } = useUpdateBill();

  const disabledTabs = { [Tab.RECURRENCE]: true };

  function handleUpdateBill(params: BillFormParams) {
    if (!billId) return;

    updateBill(
      {
        ...parseFormToSubmit(params),
        companyId,
        organizationId,
        billId,
        inboxItemId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message:
              'O item selecionado foi vinculado ao lançamento existente.',
          });

          onClose();
          onSuccess?.();
        },
        onError: ({ response }: ApiError<ApiErrorForm>) => {
          snackbar({
            variant: 'error',
            message:
              'Não foi possível vincular o item selecionado ao lançamento.',
          });

          if (response?.data.errors && isStructuredFormError(response?.data)) {
            setErrorsInForm(response.data.errors, form.setError);
          }
        },
      }
    );
  }

  return (
    <EntryModal<BillFormParams>
      onClose={onClose}
      open={open}
      onExit={onExit}
      onSubmit={handleUpdateBill}
      isSaving={isSaving}
      form={form}
      Header={(props) => <ModalHeader {...props} onSuccess={onClose} />}
      isLoading={isLoading}
      inboxItemId={inboxItemId}
      inboxAttachmentsStatus={inboxAttachmentsStatus}
      disabledTabs={disabledTabs}
      isEditMode
      editableFields={bill?.editableFields}
      suggestedFields={bill?.suggestedFields}
      contact={bill?.contact}
    >
      <BillContent bill={bill} />
    </EntryModal>
  );
}
