import t from 'zod';

import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import { CategoryDecoder } from 'src/libs/finbits/Categories/categories/types';
import { ScheduledEntryDecoder } from 'src/libs/finbits/Management/ScheduledEntries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';

export const FinancialEntryDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  description: t.string(),
  originDescription: t.string().nullable(),
  categoryId: t.string().nullable(),
  category: CategoryDecoder.nullish(),
  contactId: t.string().nullable(),
  contact: ContactDecoder.nullish(),
  transactionId: t.string(),
  accountId: t.string(),
  account: AccountDecoder.optional(),
  relevantDate: t.string().nullable(),
  comments: t.string().nullable(),
  notaFiscalNumber: t.string().nullish(),
  notaFiscalIssueDate: t.string().nullish(),
  dueDate: t.string().nullish(),
  attachmentsCount: t.number(),
  attachments: AttachmentDecoder.array().optional(),
  type: t.nativeEnum(BalanceType),
  date: t.string(),
  scheduledEntry: ScheduledEntryDecoder.nullish(),
  manuallyCreated: t.boolean().nullish(),
  labels: LabelDecoder.array().optional(),
  unfilledContextFields: t.string().array().nullable(),
  notaFiscalId: t.string().nullish(),
});

export type FinancialEntry = t.TypeOf<typeof FinancialEntryDecoder>;

export type FinancialEntriesEditable = {
  id: string;
  description?: string;
  categoryId: string | null;
  contactId: string | null;
  relevantDate: string | null;
  comments?: string | null;
  labelsIds?: string[];
};

export type FinancialEntryGetParams = {
  organizationId?: string;
  companyId?: string;
  financialEntryId: string;
};

export type FinancialEntryConciliateParams = {
  organizationId: string;
  companyId: string;
  financialEntryId: string;
  scheduledEntryId?: string;
  billId?: string;
};

export type FinancialEntryUnconciliateParams = {
  organizationId: string;
  companyId: string;
  financialEntryId: string;
};

export type FinancialEntryPatchParams = FinancialEntryGetParams & {
  description?: string;
  categoryId?: string | null;
  contactId?: string | null;
  relevantDate?: Date | null;
  attachmentsIds?: string[];
  labelsIds?: string[];
  comments?: string | null;
};

export type FinancialEntryPostParams = {
  organizationId: string;
  companyId: string;
  description: string;
  categoryId?: string | null;
  contactId?: string | null;
  comments?: string | null;
  relevantDate?: Date | null;
  attachmentsIds?: string[];
  amount: number;
  date: Date;
  accountId: string;
  type: BalanceType;
  labelsIds?: string[];
};

export type FinancialDoneEntry =
  | {
      errors: {
        billId: string;
        errors: string;
      }[];
      message: string;
    }
  | string
  | undefined;
