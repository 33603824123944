import type { SxProps, Theme } from '@mui/material';

export const content: SxProps<Theme> = {
  height: '100%',
  backgroundColor: 'background.default',
  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
  py: 5,
};

export const details: SxProps<Theme> = {
  maxHeight: '100vh',
  height: '100%',

  overflowY: 'auto',
};
