import { AppBar, Button, Stack, Toolbar } from '@mui/material';
import { useKBar } from 'kbar';
import { Link } from 'react-router-dom';
import { SearchIcon } from 'src/mui/_icons';

import { IconButton, KeyboardShortcut } from 'src/mui';

import { Logo } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';

import InitialBalanceMenu from './InitialBalanceMenu';
import Navigation from './Navigation';
import NotificationsMenu from './NotificationsMenu';
import SettingsMenu from './SettingsMenu';
import styles from './TopMenu.module.scss';
import UserMenu from './UserMenu';
import HelpCenterMenu from './HelpCenterMenu';

export default function TopMenu() {
  const { user } = useProfile();

  const { query } = useKBar();

  const hasCompany = Boolean(user?.companies?.length);

  return (
    <AppBar position="sticky" color="inherit" className={styles.container}>
      <Stack px={8}>
        <Toolbar disableGutters className={styles.toolbar}>
          <Link
            className={styles.logoLink}
            to="/"
            aria-label="Início"
            role="link"
          >
            <Logo />
          </Link>

          {hasCompany && <Navigation />}

          <Stack direction="row" alignItems="center" gap={4} ml="auto">
            <Button
              variant="text"
              onClick={query.toggle}
              endIcon={<KeyboardShortcut macOS="cmd + k" windows="ctrl + k" />}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              Atalhos
            </Button>

            {hasCompany && (
              <Stack direction="row" gap={1}>
                <IconButton
                  size="large"
                  onClick={query.toggle}
                  sx={{
                    display: { xs: 'flex', lg: 'none' },
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <InitialBalanceMenu />
                <NotificationsMenu />
                <HelpCenterMenu />
                <SettingsMenu />
              </Stack>
            )}
            <UserMenu />
          </Stack>
        </Toolbar>
      </Stack>
    </AppBar>
  );
}
