import { Typography } from 'src/design-system/components';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormField } from 'src/mui/_scss';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';

import { SicoobAutoPaymentTermResolver } from 'src/libs/finbits/Bank/Sicoob';

import AutoPaymentCard from '../../AutoPaymentModal/AutoPaymentCard';
import type { StepperContentProps } from '../../VerticalStepper/types';

import styles from './InstructionsStep.module.scss';

export default function InstructionsStep({
  onNext,
  onPrev,
}: StepperContentProps) {
  const form = useForm({
    resolver: zodResolver(SicoobAutoPaymentTermResolver),
    mode: 'onChange',
    defaultValues: {
      term: false,
    },
  });

  async function handleSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (isValid) {
      onNext?.(event);
    }
  }

  return (
    <>
      <Box className={styles.cards}>
        <AutoPaymentCard
          stepIndex={1.1}
          heading="Acesse o Portal do Sicoob para Desenvolvedores"
          buttonLabel="Acessar Banco Sicoob"
          buttonHref="https://developers.sicoob.com.br/portal/login"
          buttonTarget="_blank"
        >
          <Typography variant="text-sm" fontWeight="400">
            Crie sua conta ou entre com seu e-mail e senha no Portal do Sicoob
            pelo link abaixo.
          </Typography>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={1.2}
          heading="No Portal Sicoob, crie suas APIs e obtenha a credencial"
          buttonLabel="Ver passo a passo"
          buttonHref="https://www.finbits.com.br/central-de-ajuda/sua-conexao-sicoob"
          buttonTarget="_blank"
        >
          <Typography fontWeight="400">
            Siga o passo a passo abaixo para criar as seguintes APIs:
          </Typography>

          <ul className={styles.list}>
            <li>Cobrança bancária Pagamentos</li>
            <li>Conta corrente</li>
            <li>Pix Pagamentos</li>
            <li>SBP Transferências</li>
          </ul>

          <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Typography
                variant="text-sm"
                color="grey.600"
                fontWeight={600}
                marginBottom={4}
              >
                <strong>
                  A criação das APIs e obtenção da credencial requer a aprovação
                  dos responsáveis pela conta Sicoob.
                </strong>
              </Typography>
            </CardContent>
          </Card>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={1.3}
          heading="Volte ao app da FinBits com a credencial gerada"
        >
          <Typography fontWeight="400">
            Ao final do processo de criação da API,{' '}
            <strong>obtenha as seguintes credenciais</strong> geradas pelo Banco
            Sicoob:
          </Typography>

          <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
              <ul className={styles.list}>
                <li>Client ID</li>
              </ul>
            </CardContent>
          </Card>
        </AutoPaymentCard>
      </Box>

      <Card
        variant="outlined"
        className={cn(styles.termCard, {
          [styles.error]: form.formState.errors.term,
        })}
      >
        <CardContent className={styles.cardContent}>
          <FormProvider {...form}>
            <FormField name="term" showErrors={false}>
              {(field) => (
                <FormControlLabel
                  {...field}
                  control={
                    <Checkbox
                      size="small"
                      className={cn({
                        [styles.error]: form.formState.errors.term,
                      })}
                    />
                  }
                  label="Tenho a credencial gerada pelo Banco Sicoob."
                  className={styles.formLabel}
                />
              )}
            </FormField>
          </FormProvider>

          <Typography
            variant="text-sm"
            color="grey.600"
            fontWeight={400}
            marginBottom={4}
          >
            Confirme que já possui a credencial para seguir para próxima etapa.
          </Typography>
        </CardContent>
      </Card>

      {form.formState.errors.term && (
        <Typography
          variant="text-sm"
          fontWeight={400}
          marginTop="5px"
          className={styles.error}
        >
          {form.formState.errors.term.message}
        </Typography>
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="38px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Continuar
        </Button>
      </Box>
    </>
  );
}
