import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import type { UseFormProps } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { Typography } from 'src/design-system/components';

import type { CategoryParams } from 'src/libs/finbits/Categories/categories/types';
import { CategoryResolver } from 'src/libs/finbits/Categories/categories/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './CategoryForm.module.scss';

type Props = {
  disabledFields?: Array<keyof CategoryParams>;
  defaultValues?: UseFormProps<CategoryParams>['defaultValues'];
  responseError?: Record<string, string | string[]>;
  onSubmit: (params: CategoryParams) => void;
};

export default function CategoryForm({
  disabledFields,
  defaultValues,
  responseError,
  onSubmit,
}: Props) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CategoryParams>({
    defaultValues: {
      name: '',
      type: '',
      optionalAttachments: false,
      notIncludeInCashFlow: false,
      ...defaultValues,
    },
    resolver: zodResolver(CategoryResolver),
  });

  useEffect(() => {
    if (responseError) {
      Object.entries(responseError).forEach(([field, message]) => {
        setError(field as keyof CategoryParams, {
          message: Array.isArray(message) ? message.join('.') : message,
        });
      });
    }
  }, [responseError, setError]);

  return (
    <Stack
      component="form"
      id="category-form"
      onSubmit={handleSubmit(onSubmit)}
      gap={6}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            disabled={disabledFields?.includes('name')}
            margin="dense"
            label="Nome"
            placeholder="Digite o nome da categoria"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <FormControl
            error={!!errors.type}
            disabled={disabledFields?.includes('type')}
          >
            <InputLabel>Tipo</InputLabel>
            <ToggleButtonGroup
              exclusive
              fullWidth
              disabled={disabledFields?.includes('type')}
              {...field}
            >
              <ToggleButton value={BalanceType.CREDIT} aria-label="Entrada">
                Entrada
              </ToggleButton>
              <ToggleButton value={BalanceType.DEBIT} aria-label="Saída">
                Saída
              </ToggleButton>
            </ToggleButtonGroup>
            {errors.type?.message && (
              <FormHelperText>{errors.type?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        name="optionalAttachments"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            disabled={disabledFields?.includes('optionalAttachments')}
            label={
              <Box>
                <Typography>Dispensar obrigatoriedade de anexo</Typography>
                <Typography variant="text-xs" color="grey.500">
                  Por padrão, é obrigatória a inclusão de anexos em todos os
                  lançamentos. Ao marcar esta opção, os anexos serão opcionais
                  para lançamentos desta categoria.
                </Typography>
              </Box>
            }
            control={
              <Checkbox
                checked={field.value}
                className={styles.checkbox}
                {...field}
              />
            }
            classes={{ root: styles.label }}
          />
        )}
      />

      <Controller
        name="notIncludeInCashFlow"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            disabled={disabledFields?.includes('notIncludeInCashFlow')}
            classes={{ root: styles.label }}
            label={
              <Box>
                <Typography>
                  Desconsiderar na visualização do fluxo de caixa
                </Typography>
                <Typography variant="text-xs" color="grey.500">
                  Marque esta opção quando quiser que esta categoria represente
                  lançamentos como transferências entre contas da empresa,
                  investimentos ou outras transações que devem ser
                  desconsideradas na visualização do fluxo de caixa.
                </Typography>
              </Box>
            }
            control={
              <Checkbox
                className={styles.checkbox}
                checked={field.value}
                {...field}
              />
            }
          />
        )}
      />
    </Stack>
  );
}
