import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateBillsQueries } from 'src/libs/finbits/Bills';
import { invalidateOverviewQueries } from 'src/libs/finbits/Overview';

import type { RestoreEntryPostParams } from './';

async function postRestoreEntry({
  organizationId,
  companyId,
  ...params
}: RestoreEntryPostParams): Promise<any> {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/entries/restore`,
    params
  );

  return response.data;
}

export function useRestoreEntry() {
  const queryClient = useQueryClient();

  const { mutate: restoreEntry, ...rest } = useMutation<
    unknown,
    ApiError,
    RestoreEntryPostParams
  >(postRestoreEntry, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
      invalidateBillsQueries(queryClient);
      invalidateOverviewQueries(queryClient);
    },
  });

  return { restoreEntry, ...rest };
}
