import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import type { RcFile } from 'rc-upload/lib/interface';
import { Typography } from 'src/design-system/components';
import { CloseIcon } from 'src/mui/_icons';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useImportClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { CLASSIFICATION_SPREADSHEET_TEMPLATE } from '../enum';

import styles from './ImportClassificationModal.module.scss';
import UploadContent from './UploadContent';

export default function ImportClassificationModal({
  onClose,
  onExit,
  open,
}: PortalProps) {
  const { createClassifications, isLoading: isUploading } =
    useImportClassifications();
  const { companyId, organizationId } = useCompanyParams();

  function importRequest(file: RcFile) {
    const requestParams = { organizationId, companyId, file };

    createClassifications(requestParams, {
      onSuccess: () => {
        onClose();
        snackbar({
          variant: 'success',
          message: 'Planilha importada com sucesso.',
        });
      },
      onError: () => {
        snackbar({
          variant: 'error',
          title: 'A importação não foi realizada',
          message: (
            <span>
              Por favor, verifique a planilha enviada e certifique-se de que ela
              segue o{' '}
              <a href={CLASSIFICATION_SPREADSHEET_TEMPLATE} download>
                modelo disponibilizado.
              </a>
            </span>
          ),
        });
      },
    });
  }

  return (
    <Dialog
      open={open ?? false}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Typography variant="text-lg" component="span" fontWeight="600">
          Importar planilha de etiquetas
        </Typography>

        <IconButton
          aria-label="Fechar modal importação de etiquetas"
          onClick={onClose}
          className={styles.closeIcon}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <UploadContent
          onImportRequest={importRequest}
          isUploading={isUploading}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
